export const MAINNET = 'main';
export const RINKEBY = 'rinkeby';

export const IS_NETWORK_RINKEBY = process.env.REACT_APP_NETWORK === RINKEBY;

const networkConfig = {
  [MAINNET]: {
    id: 1,
    chainId: 1,
    name: 'Mainnet',
    network: 'mainnet',
    domain: 'blanknetwork.io',
    webDomain: 'https://blank.art',
    etherscan: 'https://etherscan.io/tx',
    etherscanAddressLink: 'https://etherscan.io/address',
    factoryAddress: '0xd76f16e38d152cac0cfbe1dc1d844cbfa3e2ab0d',
    marketAddress: '0x4662c5fdd44239f0482251a6b81e358cd1ba9549',
    blanknftAddress: '0x01bdba36e0b921a4c69d1c33a76521a2356b7e4d',
    soloLogicAddress: '0x910054b46a72e0e3d401b0f638aa21c15ed2cc40',
    collabLogicAddress: '0x2388266a3eb3bfe87771cb0cc316e440edc57384',
    groupLogicAddress: '0x1ac750d5eb24cee629a97de83473821e7e215c79',
    volatilityArtLogicAddress: '0x0cc7100ba9f971818ad27dbb35ca92112b8302ea',
    volatilityColectionV1Address: '0xbc6c831a566eb4018b13968c45304cfc3c674df8',
    whitelistCollab: '0xaAF165B5D53276c4aE05E0D405dc95F4b9e15bBf',
    whitelistGroup: '0x20fDbE853F76Ef7C1E204653C8343DC12D287556',
    openEdition: '0xd49f9c4026ce6fa9a2d95527508406351283ba5c',
    blankAdmin: '0xcd1efDD9e0c9cbb7149878252366c71159697677',
  },
  [RINKEBY]: {
    id: 4,
    chainId: 4,
    name: 'Rinkeby Testnet',
    network: 'rinkeby',
    domain: 'rinkeby.blanknetwork.io',
    webDomain: 'https://dev.blank.art',
    etherscan: 'https://rinkeby.etherscan.io/tx',
    etherscanAddressLink: 'https://rinkeby.etherscan.io/address',
    cryptoPunkAddress: '0x2e5982104196d829bf0d5c2edacf29ab9d61e900', // for bounty
    bountyAddress: '0x0c8a1edf307a38ee57848dc1e0f71a3e5365b59e', // for bounty
    factoryAddress: '0x08e11f02861c5560f5a3c6e881df3ea5e70d48e7',
    marketAddress: '0x38b4a7eb1ddb3ee90b9c43855f003e0609f9cbfc',
    blanknftAddress: '0xea491a46172342617d208d52c21dddb8262afac9',
    soloLogicAddress: '0x43930522623df5e90729872ba8e9fb404b5c5487',
    collabLogicAddress: '0x67813fafd27e715783b8542a1428e752c208e85c',
    groupLogicAddress: '0xff76c4921065f97774e71727fba1558f133d6102',
    volatilityArtLogicAddress: '0xeb65b44c8bd0172796de2b217bafe95737bccfac',
    volatilityColectionV1Address: '0x4ab6dcc50c95fae4c656906d52b9c0de534d7196',
    whitelistCollab: '0xefb4EC359169DCd6Ff144bD6E05A86b355cD6E50',
    whitelistGroup: '0x5f5Fd44b918444b6AB27575434a20778de282Aec',
    openEdition: '0x1486cd9cc6ea2b45185447c395180779316d45da',
    blankAdmin: '0x95298790beB442F204E3864c5BD4073905185108',
  },
};

export const {
  id: NETWORK_ID,
  chainId: CHAIN_ID,
  name: NETWORK_NAME,
  domain: DOMAIN,
  webDomain: WEB_DOMAIN,
  network: NETWORK,
  etherscan: ETHERSCAN_DOMAIN,
  etherscanAddressLink: ETHERSCAN_ADDRESS_DOMAIN,
  factoryAddress: FACTORY_ADDRESS,
  marketAddress: MARKET_ADDRESS,
  blanknftAddress: BLANKNFT_ADDRESS,
  bountyAddress: BOUNTY_ADDRESS,
  cryptoPunkAddress: CRYPTO_PUNK_ADDRESS,
  soloLogicAddress: SOLO_LOGIC_ADDRESS,
  collabLogicAddress: COLLAB_LOGIC_ADDRESS,
  groupLogicAddress: GROUP_LOGIC_ADDRESS,
  volatilityArtLogicAddress: VOLATILITY_ART_LOGIC_ADDRESS,
  volatilityColectionV1Address: VOLATILITY_COLECTION_V1_ADDRESS,
  whitelistCollab: WHITE_LIST_COLLAB,
  whitelistGroup: WHITE_LIST_GROUP,
  openEdition: OPEN_EDITION,
  blankAdmin: BLANK_ADMIN,
} = networkConfig[process.env.REACT_APP_NETWORK || MAINNET];
