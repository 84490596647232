import { useField } from 'formik';
import React from 'react';

import { Box } from '../atoms';

const InputWrapper = ({ name, label, labelProps, children, ...props }) => {
  const [, meta] = useField(name);

  return (
    <Box mb={24} {...props}>
      {label && (
        <Box mb={12} fontWeight="bold" {...labelProps}>
          {label}
        </Box>
      )}
      {children}
      {meta.touched && meta.error && (
        <Box color="red" fontSize="1rem" mt={1}>
          {meta.error}
        </Box>
      )}
    </Box>
  );
};

export default InputWrapper;
