import React from 'react';

import { Flex } from '../../../atoms';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ home, dapp, children, bodyProps, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      bg="background"
      color="text"
      fontSize={4}
      fontFamily="normal"
      pt={90}
      {...props}
    >
      <Header home={home} dapp={dapp} />
      <Flex flex={1} overflowX="auto" {...bodyProps}>
        {children}
      </Flex>
      <Footer home={home} dapp={dapp} />
    </Flex>
  );
};

export default Layout;
