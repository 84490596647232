export default {
  colors: {
    white: '#fff',
    black: '#000',
    red: '#f00',
    text: '#000',
    textOvelay: 'rgba(0, 0, 0, 0.8)',
    textSecondary: '#818181',
    textHeader: 'rgba(0, 0, 0, 0.5)',
    background: '#fff',
    primary: '#000',
    primaryPurple: '#AB05F2',
    secondary: '#00A3FF',
    secondaryHover: '#39B7FF',
    primaryDisabled: '#E9E9E9',
    primaryTextDisabled: '#C4C4C4',
    textLight: 'rgba(0, 0, 0, 0.6)',
    backgroundLight: 'rgba(255, 255, 255, 0.7)',
    primaryNormalLight: 'rgba(0, 0, 0, 0.5)',
    primaryLight: 'rgba(0, 0, 0, 0.3)',
    primarySemiLight: 'rgba(0, 0, 0, 0.1)',
    primarySuperLight: 'rgba(0, 0, 0, 0.03)',
    tab: 'rgba(0, 0, 0, 0.2)',

    green: '#1DF290',
    completed: '#17C25F',
  },
  gradientColors: {
    primary: 'linear-gradient(90deg, #00F5A0 0%, #00D9F5 100%)',
  },
  fonts: {
    normal: 'Open Sans',
    special: 'Raleway',
  },
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    '0.8rem',
    '1rem',
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.4rem',
    '2.8rem',
    '3.2rem',
    '4.8rem',
  ],
  space: [
    0,
    '0.4rem',
    '0.8rem',
    '1.6rem',
    '3.2rem',
    '6.4rem',
    '12.8rem',
    '25.6rem',
    '51.2rem',
  ],
  variants: {
    button: {
      secondary: {
        p: '12.5px 30px',
        border: 'none',
        fontWeight: '600',
        color: 'textHeader',
        bg: 'background',
        borderRadius: '77px',
        fontSize: 14,
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
        lineHeight: '140%',
        ':disabled': {
          color: 'primaryLight',
          cursor: 'default',
          boxShadow: 'none !important',
        },
        ':hover': {
          boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        },
      },
      primary: {
        p: '12.5px 30px',
        border: 'none',
        fontWeight: '600',
        color: 'background',
        bg: 'secondary',
        borderRadius: '77px',
        fontSize: 14,
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
        lineHeight: '140%',
        ':hover': {
          bg: 'secondaryHover',
          boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        },
        ':disabled': {
          color: 'primaryTextDisabled',
          bg: 'primaryDisabled',
          cursor: 'default',
          boxShadow: 'none !important',
        },
      },
      link: {
        color: 'text',
        fontSize: 18,
        lineHeight: '170%',
        bg: 'transparent',
        fontWeight: 'normal',
        textAlign: 'center',
        border: 'none',
        textDecoration: 'underline',
        p: 0,
        ':hover': {
          bg: 'transparent !important',
          boxShadow: 'none !important',
        },
        ':disabled': {
          color: 'primaryTextDisabled',
          bg: 'transparent !important',
          cursor: 'default',
          textDecoration: 'none',
        },
      },
      reverse: {
        color: 'background',
        bg: 'primary',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 2,
        p: '0.6rem 1.4rem',
      },
      outline: {
        color: 'primary',
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 2,
        p: '0.6rem 1.4rem',
      },
    },
    input: {
      primary: {
        p: '12px 20px',
        border: '0.5px solid',
        borderColor: 'primaryLight',
        borderRadius: 100,
        color: 'text',
        fontWeight: '600',
        fontSize: 16,
        lineHeight: '100%',
        css: {
          '::placeholder': {
            color: 'primaryLight',
          },
        },
      },
      elegant: {
        py: 2,
        border: 'none',
        borderBottom: '1px solid',
        borderColor: 'textLight',
        ':not(:placeholder-shown)': {
          borderColor: 'text',
        },
        bg: 'transparent',
      },
    },
  },
};
