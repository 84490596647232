export const HOME_ROUTE = '/';
export const ABOUT_ROUTE = '/about';
export const HACKATAO_ROUTE = '/hackatao';
export const FAQ_ROUTE = '/faq';
export const CONTACT_ROUTE = '/contact';
export const PRIVACY_ROUTE = '/privacy';
export const MINT_ROUTE = `/mint`;
export const SETTING_ROUTE = '/setting';
export const EXPLORE_ROUTE = '/explore';
export const DEFAULT_ROUTE = '/default';
export const ADMIN_ROUTE = '/admin';

// export const MARKET_ROUTE = '/market';
// export const MARKET_ASSET_ROUTE = `${MARKET_ROUTE}/collections/:collectionKey/assets/:id`;

export const WHITE_WALL_ROUTE = `/whitewall/:account/:tab`;
export const WHITE_WALL_EXPLORE_COLLECTION_ROUTE = `${WHITE_WALL_ROUTE}/:collectionKey/:collectionType`;
export const WHITE_WALL_ART_WORK_ROUTE = `${WHITE_WALL_EXPLORE_COLLECTION_ROUTE}/assets/:id`;
// export const WALL_ASSET_ROUTE = `${WHITE_WALL_ROUTE}/collections/:collectionKey/assets/:id`;

export const VIEWER_ROUTE = '/viewer';

export const COLLECTIONS_ROUTE = `${VIEWER_ROUTE}/studio`;
export const COLLECTION_ROUTE = `${COLLECTIONS_ROUTE}/:collectionKey/:collectionType`;
export const ART_WORK_ROUTE = `${COLLECTION_ROUTE}/assets/:id`;

export const COLLECTION_PUBLIC_ROUTE =
  '/collections/:collectionKey/:collectionType';
export const ART_WORK_PUBLIC_ROUTE = `${COLLECTION_PUBLIC_ROUTE}/assets/:id`;

export const BOUNTIES_ROUTE = `/bounties`;
export const NEW_BOUNTY_ROUTE = `${BOUNTIES_ROUTE}/new`;
export const BOUNTY_DETAIL_ROUTE = `${BOUNTIES_ROUTE}/detail/:bountyId`;

export const BLANK_ARTISTS_ROUTE = `${COLLECTIONS_ROUTE}/blank1`;

export const OPEN_EDITION_ASSET_ROUTE = '/open-edition/:collectionKey/:id';
export const OPEN_EDITION_COLLECTION_ROUTE = '/open-edition/:collectionKey';
export const PRESIGN_OPEN_EDITION_PAGE = '/OpenEdition-Presign/:collectionKey';
