import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { isEmpty, pipe, propOr, flip, lte } from 'ramda';
import * as Yup from 'yup';

import { TRANSACTION_STATUS } from '@blank/common/constants';
import { usePostFormData } from '@blank/hooks/usePost';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { getRandomColor } from '@blank/utils';
import CollectionFormModal from './CollectionFormModal';
import {
  COLLECTION_TYPES,
  MINT_THUMBNAIL_TYPES,
  BYTE_PER_MB,
  MINT_MAX_THUMBNAIL_SIZE,
} from '@blank/constants';
import CollectionSuccessModal from './CollectionSuccessModal';
import {
  getLogicAddressByCollectionType,
  createCreationMemoryData,
  createCollabMemoryData,
  createGroupMemoryData,
  createVolatilityMemoryData,
} from '@blank/utils/collection.util';

const CollectionSchema = (type) =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    file: Yup.mixed()
      .test(
        'fileType',
        'File type is incorrect. Correct type: JPG/PNG/GIF',
        (file) => {
          return !file || MINT_THUMBNAIL_TYPES.includes(file?.type);
        }
      )
      .test(
        'fileSize',
        `File size is too large. Maximum: ${MINT_MAX_THUMBNAIL_SIZE}MB`,
        pipe(
          propOr(0, 'size'),
          flip(lte)(MINT_MAX_THUMBNAIL_SIZE * BYTE_PER_MB)
        )
      ),
    symbol: Yup.string().required('Symbol is required'),
    ...(type === COLLECTION_TYPES.collabs.type && {
      royalties: Yup.number()
        .required('Royalties is required')
        .typeError('Must be a number')
        .min(0, 'Must be >= 0')
        .max(100, 'Must be <= 100')
        .label('Royalties'),
    }),
    ...(type === COLLECTION_TYPES.groups.type && {
      commission: Yup.number()
        .required('Commission is required')
        .typeError('Must be a number')
        .min(0, 'Must be >= 0')
        .max(100, 'Must be <= 100')
        .label('Commission'),
    }),
  });

const InformationForm = ({
  type = COLLECTION_TYPES.creations.type,
  onClose,
  createCollection,
  status,
  isSigning,
  hash,
  collectionAddress,
  setShowInviteModal,
}) => {
  const logicAddress = getLogicAddressByCollectionType(type);
  const account = useAccount();
  const isCollab = type === COLLECTION_TYPES.collabs.type;
  const isGroup = type === COLLECTION_TYPES.groups.type;
  const isVolatility = type === COLLECTION_TYPES.volatilityArt.type;
  const [uploadMetadata, { loading }] = usePostFormData('/api/ipfs', {});

  const getMemoryData = useCallback(
    async (values) => {
      if (isCollab) {
        return await createCollabMemoryData({
          creator: account,
          name: values.name,
          symbol: values.symbol,
          royalty: 100 * +values.royalties,
        });
      }
      if (isGroup) {
        return await createGroupMemoryData({
          creator: account,
          name: values.name,
          symbol: values.symbol,
          commission: 100 * +values.commission,
        });
      }

      if (isVolatility) {
        return await createVolatilityMemoryData({
          creator: account,
          name: values.name,
          symbol: values.symbol,
        });
      }

      return await createCreationMemoryData({
        creator: account,
        name: values.name,
        symbol: values.symbol,
      });
    },
    [account, isCollab, isGroup, isVolatility]
  );

  return (
    <>
      <Formik
        initialValues={{
          type,
          name: '',
          description: '',
          background: getRandomColor(),
          royalties: 0,
          commission: 0,
          invites: [],
        }}
        validationSchema={CollectionSchema(type)}
        onSubmit={(values) => {
          uploadMetadata({
            name: values.name,
            background: values.background,
            ...(!isEmpty(values.description) && {
              description: values.description,
            }),
            ...(values.file && {
              file: values.file,
            }),
            ...(isCollab && {
              royalty: 100 * +values.royalties,
            }),
            ...(isGroup && {
              royalty: 100 * +values.commission,
            }),
          }).then(async (data) => {
            if (data.hashes && data.hashes[0]) {
              const memoryData = await getMemoryData(values);
              createCollection([logicAddress, data.hashes[0], memoryData], {
                from: account,
              });
            }
          });
        }}
      >
        {({ values }) => {
          return collectionAddress ? (
            <CollectionSuccessModal
              transactionHash={hash}
              open
              onClose={() =>
                onClose({
                  id: collectionAddress.toLowerCase(),
                  name: values.name,
                })
              }
              onNext={() => setShowInviteModal(true)}
            />
          ) : (
            <CollectionFormModal
              submitDisabled={loading || isSigning}
              onClose={onClose}
              inProcess={
                loading || isSigning || status === TRANSACTION_STATUS.pending
              }
            />
          );
        }}
      </Formik>
    </>
  );
};

export default InformationForm;
