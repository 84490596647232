import { Form, Formik, useField } from 'formik';
import { flip, lte, pipe, propOr } from 'ramda';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Box, Button, Flex } from '../../../atoms';
import { usePostFormData } from '../../../hooks/usePost';
import { useUpdateProfile, useUserInfo } from '../ProfileContext';
import CurrentUser from '../CurrentUser';
import FileInput from '../../../components/FileInput';
import FormCustom from '../../../components/FormCustom';
import InputWrapper from '../../../components/InputWrapper';
import Modal from '../../../components/Modal';
import { RESET_FETCH_DATA } from '../../../hooks/useFetch';
import Spinner from '../../../components/Spinner';

const MAX_FILE_SIZE = 10; //MB
const BYTE_PER_MB = 1000000;

const UserSchema = Yup.object().shape({
  cover: Yup.mixed().test(
    'fileSize',
    'File size is too large',
    pipe(propOr(0, 'size'), flip(lte)(MAX_FILE_SIZE * BYTE_PER_MB))
  ),
});

const FormCover = ({ name, ...props }) => {
  const [{ value }] = useField(name);
  const srcProp = value && { src: URL.createObjectURL(value) };

  return (
    <Box {...props} alignItems="center">
      <Box mb={20} width="100%" height="200px">
        <CurrentUser.Cover mr={3} hideEditBtn {...srcProp} />
      </Box>
      <Box fontSize={0}>
        <FormCustom
          Component={FileInput}
          name={name}
          inline
          accept="image/*"
          btnProps={{
            variant: 'outline',
            fontWeight: 'bold',
          }}
        >
          Choose File
        </FormCustom>
        <Box mt={1} color="grey">
          JPG, PNG, GIF. Max size: {MAX_FILE_SIZE}MB
        </Box>
      </Box>
    </Box>
  );
};

const EditCoverButton = ({ ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const user = useUserInfo();
  const updateProfile = useUpdateProfile();

  const lableText = 'Edit Cover Photo';

  const [callApiUpdate, { loading, data, dispatch }] = usePostFormData(
    '/api/users/cover',
    {
      jwt: true,
    }
  );

  const openPopup = () => {
    setOpenModal(true);
    dispatch({ type: RESET_FETCH_DATA });
  };

  if (!user) {
    return <Spinner m="auto" />;
  }

  return (
    <>
      {!openModal && (
        <Button {...props} onClick={openPopup}>
          {lableText}
        </Button>
      )}
      <Modal
        open={openModal}
        loading={loading}
        position="relative"
        width="60rem"
        p="4rem"
      >
        {data && (
          <Flex
            center
            position="absolute"
            top={0}
            left={0}
            right={0}
            bg="primary"
            color="background"
            fontSize={2}
            p="1.2rem"
          >
            Your cover is successfully updated
          </Flex>
        )}
        <Formik
          initialValues={{}}
          validationSchema={UserSchema}
          onSubmit={({ cover }) => {
            callApiUpdate({
              ...(cover && {
                image: cover,
              }),
            }).then((data) => {
              updateProfile({
                ...data,
              });
            });
          }}
        >
          <Form>
            <Flex flexDirection="column" color="text">
              <InputWrapper name="cover" label="Cover">
                <FormCover name="cover" />
              </InputWrapper>
              <Flex center flexDirection="column" fontSize={3}>
                <Button type="submit" width="17rem" mb={2}>
                  Save
                </Button>
                <Button
                  type="button"
                  variant="link"
                  textDecoration="underline"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default EditCoverButton;
