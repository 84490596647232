import React, { createContext, useContext } from 'react';
import { useFetchOnMount } from '@blank/hooks/useFetch';

const VolatilityContext = createContext();

export const VolatilityProvider = ({ children }) => {
  const { data } = useFetchOnMount('/api/collections/volatility-art');
  const v2Address = data?.collection;

  return (
    <VolatilityContext.Provider value={[v2Address]}>
      {children}
    </VolatilityContext.Provider>
  );
};

export const useVolatilityV2Address = () => {
  const [v2Address] = useContext(VolatilityContext);

  return v2Address ? v2Address.toLowerCase() : v2Address;
};

export default VolatilityContext;
