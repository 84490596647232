import React from 'react';
import {
  useWrongNetwork,
  useCheckWaitingLogin,
} from '@blank/common/WalletContext/hooks';
import WrongNetworkModal from './WrongNetworkModal';
import ProcessingModal from '@blank/components/ProcessingModal';

// const checkMetamask = () =>
//   window.web3 && window.web3.currentProvider.isMetaMask;

const LoadingContainer = ({ children }) => {
  const isWrongNetwork = useWrongNetwork();
  const checkInject = useCheckWaitingLogin();
  if (isWrongNetwork) {
    return (
      <>
        <WrongNetworkModal />
        {children}
      </>
    );
  }
  if (checkInject) {
    return (
      <>
        <ProcessingModal open children="" />
        {/* {children} */}
      </>
    );
  }
  return children;
};

export default LoadingContainer;
