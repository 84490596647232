import { dropLast, last } from 'ramda';
import { useLocation } from 'react-router-dom';

import React from 'react';

import {
  COLLECTIONS_ROUTE,
  COLLECTION_ROUTE,
  MINT_ROUTE,
  FAQ_ROUTE,
  EXPLORE_ROUTE,
  SETTING_ROUTE,
  PRIVACY_ROUTE,
  ABOUT_ROUTE,
  WHITE_WALL_ROUTE,
  WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  COLLECTION_PUBLIC_ROUTE,
  ART_WORK_ROUTE,
  ART_WORK_PUBLIC_ROUTE,
  WHITE_WALL_ART_WORK_ROUTE,
  DEFAULT_ROUTE,
  ADMIN_ROUTE,
} from '@blank/pages/routeConstants';
import routeConfigs from '@blank/pages/routerConfigs';

import { Box, Link, Text, Flex } from '@blank/atoms';
import UserBreadcrumb from './UserBreadcrumb';
import CollectionBreadcrumb from './CollectionBreadcrumb';
import AssetBreadcrumb from './AssetBreadcrumb';
import ExploreBreadcrumb from './ExploreBreadcrumb';
import StudioBreadcrumb from './StudioBreadcrumb';
import { getMatchedRouteAndPath } from '@blank/utils';
// import { useFetchOnMount } from '../../hooks/useFetch';
// import CollectionImage from './CollectionImage';
// import Container from '../../components/Container';
// import CurrentUser from './CurrentUser';
// import User from './User';
// import useCollection from './useCollection';
// import { useAccount } from '@blank/common/WalletContext/hooks';

const getBreadcrumbItems = (routes = [], params) => {
  const { account, tab, collectionKey, id, collectionType } = params;
  const getHref = (route) =>
    route
      .replace(':account', account)
      .replace(':tab', tab)
      .replace(':id', id)
      .replace(':collectionKey', collectionKey)
      .replace(':collectionType', collectionType);

  const labelByRoute = (isLast) => ({
    [COLLECTIONS_ROUTE]: <StudioBreadcrumb isLast={isLast} />,
    [COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [COLLECTION_PUBLIC_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),
    [MINT_ROUTE]: 'MINT NEW NFT',
    [DEFAULT_ROUTE]: 'Default',
    [EXPLORE_ROUTE]: <ExploreBreadcrumb isLast={isLast} />,
    [SETTING_ROUTE]: 'EDIT PROFILE',
    [PRIVACY_ROUTE]: 'PRIVACY',
    [FAQ_ROUTE]: 'FAQ',
    [ABOUT_ROUTE]: 'ABOUT',
    [ADMIN_ROUTE]: 'ADMIN PAGE',
    [WHITE_WALL_ROUTE]: <UserBreadcrumb isLast={isLast} account={account} />,
    [WHITE_WALL_EXPLORE_COLLECTION_ROUTE]: (
      <CollectionBreadcrumb isLast={isLast} collectionKey={collectionKey} />
    ),

    [ART_WORK_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    [ART_WORK_PUBLIC_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    [WHITE_WALL_ART_WORK_ROUTE]: (
      <AssetBreadcrumb isLast={isLast} collectionKey={collectionKey} id={id} />
    ),
    NOT_FOUND: '404 NOT FOUND',
  });

  const labelByRouteLast = labelByRoute(true);
  const labelByRouteNonLast = labelByRoute();

  return routes.map((route, index) => ({
    label:
      index >= routes.length - 1
        ? labelByRouteLast[route]
        : labelByRouteNonLast[route],
    href: getHref(route),
  }));
};

const BREADCRUMBS_NOTFOUND = [EXPLORE_ROUTE, 'NOT_FOUND'];

const Breadcrumbs = ({ ...props }) => {
  const location = useLocation();
  const matched = getMatchedRouteAndPath(routeConfigs, location.pathname);

  const breadcrumbsConfig = matched?.breadcrumbsConfig || BREADCRUMBS_NOTFOUND;
  const params = matched?.params || {};

  const items = getBreadcrumbItems(breadcrumbsConfig, params);
  const lastItem = last(items);

  return (
    <Box fontWeight={500} fontSize="14px" color="primaryLight" {...props}>
      <Flex display="flex" alignItems="center" px="10px" whiteSpace="nowrap">
        {dropLast(1)(items).map((item, index) => (
          <React.Fragment key={index}>
            <Link to={item.href} textDecoration="underline">
              {item.label}
            </Link>
            <Text mx="5px">/</Text>
          </React.Fragment>
        ))}
        <Text color="text">{lastItem?.label}</Text>
      </Flex>
    </Box>
  );
};

export default Breadcrumbs;
