import WalletConnectProvider from '@walletconnect/web3-provider';
// import Fortmatic from 'fortmatic';
// import Portis from '@portis/web3';
import { INFURA_ID } from '@blank/constants/wallets';

export const getProviderOptions = () => {
  const providerOptions = {
    injected: {
      package: null,
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_ID,
      },
      description: '',
    },
    // portis: {
    //   package: Portis,
    //   options: {
    //     id: PORTIS_KEY,
    //   },
    // },
    // fortmatic: {
    //   package: Fortmatic,
    //   options: {
    //     key: FORTMATIC_KEY,
    //   },
    // },
  };
  return providerOptions;
};
