import React from 'react';
import styled from 'styled-components';

import { Box } from '../atoms';

const Overlay = styled((props) => (
  <Box
    position="absolute"
    top={0}
    left={0}
    width="100%"
    height="100%"
    {...props}
  />
))``;

export default Overlay;
