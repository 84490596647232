import Web3 from 'web3';
import { INFURA_ENDPOINT } from '@blank/constants/wallets';

export const TRANSACTION_STATUS = {
  pending: 'pending',
  success: 'success',
  error: 'error',
};

export const MODAL_TYPE = {
  SET_PRICE: 'SET_PRICE',
  CANCEL_PRICE: 'CANCEL_PRICE',
  BUY: 'BUY',
  CANCEL_BID: 'CANCEL_BID',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CHANGE_PRICE: 'CHANGE_PRICE',
  TRANSFER: 'TRANSFER',
  BURN: 'BURN',
  DISCO_PRICE: 'DISCO_PRICE',
  SHOW_PRICE: 'SHOW_PRICE',
};

export const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_ENDPOINT));

export const RELOAD_AFTER_TRANSACTION_TYPES = {
  createdSolo: 1,
  createdCollab: 2,
  collection: 3,
  assetPage: 4,
  studio: 5,
};
