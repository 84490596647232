import React from 'react';

import { Box, Flex, Icon } from '../../../atoms';
import Navigator from './Navigator';
// import SubscribeForm from './SubscribeForm';

const SOCIAL_NAVS = [
  // {
  //   key: 'linkedin',
  //   label: <Icon name="social_linkedin" />,
  //   href: 'https://www.linkedin.com/company/blankart/ ',
  //   external: true,
  // },
  // {
  //   key: 'discord',
  //   label: <Icon name="social_discord" />,
  //   href: 'https://discord.gg/Bvf5m9k',
  //   external: true,
  // },
  {
    key: 'twitter',
    label: <Icon name="social_twitter" />,
    href: 'https://twitter.com/blankartnetwork ',
    external: true,
  },
  {
    key: 'reddit',
    label: <Icon name="social_reddit" />,
  },
];

const Footer = ({ home }) => (
  <Flex
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    pt={27}
    pb={25}
    px={50}
    boxShadow="0px -1px 0px rgba(0, 0, 0, 0.05)"
    wrapperProps={{ mx: '0' }}
    maxWidth="unset"
  >
    <Box flex={1} fontSize="10px" fontWeight="300" lineHeight="140%">
      © 2020 Blank Network. All rights reserved
    </Box>
    {/* <Flex flexDirection="column" alignItems="center">
      <SubscribeForm
        inputProps={{
          width: 350,
          placeholder: 'Enter your email to subsribe to our newsletter',
        }}
      />
    </Flex> */}
    <Flex flex={1} justifyContent="flex-end">
      <Navigator navs={SOCIAL_NAVS} gap={20} fontSize="1.75em" />
    </Flex>
  </Flex>
);

export default Footer;
