import React from 'react';
import { Flex, Icon, Text } from '@blank/atoms';
import styled from 'styled-components';
import { useTransactionNumberByType } from '@blank/common/TransactionContext';
import { TRANSACTION_STATUS } from '@blank/common/constants';
import FlipPending from '@blank/components/FlipPending';

const TextStatus = styled(Text)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const DATA_TRANSACTION = {
  [TRANSACTION_STATUS.pending]: {
    title: 'Pending',
    icon: <FlipPending name="pending" />,
    color: 'secondary',
  },
  [TRANSACTION_STATUS.success]: {
    title: 'Completed',
    icon: <Icon name="completed" />,
    color: 'completed',
  },
  [TRANSACTION_STATUS.error]: {
    title: 'Failed',
    icon: <Icon name="failed" />,
    color: 'red',
  },
};

const NumberTransaction = ({ type = TRANSACTION_STATUS.pending, ...props }) => {
  const numberTx = useTransactionNumberByType(type);
  if (!numberTx) {
    return <></>;
  }
  const data = DATA_TRANSACTION[type];
  return (
    <Flex center mr={16} {...props}>
      {data.icon}
      <Text
        fontWeight="bold"
        fontSize={12}
        lineHeight="100%"
        ml="8px"
        color={data.color}
        whiteSpace="nowrap"
      >
        {numberTx}
        <TextStatus>{` ${data.title}`}</TextStatus>
      </Text>
    </Flex>
  );
};

export default NumberTransaction;
