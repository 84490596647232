import React, { useState } from 'react';

import ConnectButton from '@blank/common/WalletContext/shared/ConnectButton';
import { Image, Flex } from '@blank/atoms';
import logo from '@blank/assets/images/logo.svg';

import { ADMIN_ROUTE } from '../../routeConstants';
import Navigator from './Navigator';
import UserNav from './UserNav';
import NumberTransaction from './NumberTransaction';
import { TRANSACTION_STATUS } from '@blank/common/constants';
import NewCollection from '@blank/pages/NewCollection';
// import NotificationDropdown from './NotificationDropdown';
import Breadcrumbs from '../Breadcrumbs';
// import CreateNav from './CreateNav';

const LEFT_NAVS = [
  {
    key: 'studio',
    label: <Image src={logo} height={50} />,
    href: ADMIN_ROUTE,
  },
];

const CENTER_NAVS = [
  // {
  //   key: 'explore',
  //   label: 'Explore',
  //   href: EXPLORE_ROUTE,
  //   pages: [EXPLORE_ROUTE],
  // },
  {
    key: 'breadcrumbs',
    label: <Breadcrumbs />,
  },
];

const DAPP_RIGHT_NAVS = (setLaunchSM) => [
  // ...(whiteListMember
  //   ? [
  //       {
  //         key: 'create',
  //         label: (
  //           <CreateNav
  //             whiteListMember={whiteListMember}
  //             setLaunchSM={setLaunchSM}
  //           />
  //         ),
  //       },
  //       {
  //         key: 'my_studio',
  //         label: 'MY STUDIO',
  //         href: COLLECTIONS_ROUTE,
  //         pages: [COLLECTIONS_ROUTE],
  //       },
  //     ]
  //   : [
  //       {
  //         key: 'my_nft',
  //         label: 'MY NFTS',
  //         href: COLLECTIONS_ROUTE,
  //         pages: [COLLECTIONS_ROUTE],
  //       },
  //     ]),
  // {
  //   key: 'notifications',
  //   label: <NotificationDropdown />,
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  {
    key: 'user',
    label: <UserNav setLaunchSM={setLaunchSM} />,
  },
];

const RIGHT_NAVS = [
  {
    key: 'connenct',
    label: <ConnectButton />,
  },
];

const headerStyles = {
  height: 90,
  px: 50,
  maxWidth: 'inherit',
  width: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '10',
  bg: 'background',
};

const Header = ({ home, dapp }) => {
  const [launchSM, setLaunchSM] = useState();

  return (
    <>
      {home && (
        <Flex alignItems="center" justifyContent="flex-start" {...headerStyles}>
          <Image src={logo} height={50} />
        </Flex>
      )}
      {!home && (
        <Flex center {...headerStyles} bg="background">
          <Flex flex={1} justifyContent="flex-start" zIndex={2}>
            <Navigator navs={LEFT_NAVS} fontSize={3} color="primaryLight" />
          </Flex>

          <Flex flex={2} justifyContent="center" zIndex={2}>
            <Navigator navs={CENTER_NAVS} fontSize={14} color="primaryLight" />
          </Flex>

          <Flex flex={1} justifyContent="flex-end" zIndex={2}>
            {dapp && <NumberTransaction type={TRANSACTION_STATUS.error} />}
            {dapp && <NumberTransaction type={TRANSACTION_STATUS.success} />}
            {dapp && <NumberTransaction type={TRANSACTION_STATUS.pending} />}
            <Navigator
              navs={dapp ? DAPP_RIGHT_NAVS(setLaunchSM) : RIGHT_NAVS}
              color="textLight"
              center
            />
          </Flex>
          {!!launchSM && (
            <NewCollection type={launchSM} onClose={() => setLaunchSM()} />
          )}
        </Flex>
      )}
    </>
  );
};

export default Header;
