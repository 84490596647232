import { MAINNET, RINKEBY } from './network';

export const METAMASK = 'metamask';
export const PORTIS = 'portis';
export const WALLET_CONNECT = 'walletconnect';
export const FORTMATIC = 'fortmatic';

const walletConfig = {
  [MAINNET]: {
    INFURA_ID: 'aee933d6f04146f6afed0d74daea5135',
    INFURA_ENDPOINT:
      'https://mainnet.infura.io/v3/aee933d6f04146f6afed0d74daea5135',
    FORTMATIC_KEY: 'pk_live_84113B9858EF15B7',
    PORTIS_KEY: '5736dfd1-2cd3-4eb9-bb36-0f1361425b2f',
  },
  [RINKEBY]: {
    INFURA_ID: 'a2fe9609c18f4da1ae14af55066f31f7',
    INFURA_ENDPOINT:
      'https://rinkeby.infura.io/v3/a2fe9609c18f4da1ae14af55066f31f7',
    FORTMATIC_KEY: 'pk_test_E8BCF8AB6268E008',
    PORTIS_KEY: '5736dfd1-2cd3-4eb9-bb36-0f1361425b2f',
  },
};

export const {
  FORTMATIC_KEY,
  PORTIS_KEY,
  INFURA_ID,
  INFURA_ENDPOINT,
} = walletConfig[process.env.REACT_APP_NETWORK || MAINNET];
