import { propOr } from 'ramda';
import { useCallback, useEffect, useReducer } from 'react';

const initialState = {
  loading: false,
};

const FETCH_START = 'FETCH_START';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILED = 'FETCH_FAILED';

const STATUS = {
  failed: 0,
  success: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return { loading: true };
    case FETCH_SUCCESS:
      return { loading: false, data: action.payload };
    case FETCH_FAILED:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const getCompleteUrl = (url) =>
  `${url[0] === '/' ? process.env.REACT_APP_BACKEND_DOMAIN : ''}${url}`;

export const useFetcherWithoutJwtContext = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loading, data, error } = state;

  const triggerFetch = useCallback((url, opts = {}) => {
    if (!url) return Promise.resolve();
    dispatch({ type: FETCH_START });
    return fetch(getCompleteUrl(url), opts)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          const error = new Error('API returns errors.');
          error.status = response.status;
          throw error;
        }
      })
      .then((data) => {
        if (data.status === STATUS.failed) {
          throw data.error;
        } else {
          dispatch({
            type: FETCH_SUCCESS,
            payload: propOr(data, 'data')(data),
          });
          return data.data;
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_FAILED, payload: error });
        return error;
      });
  }, []);

  return [triggerFetch, { loading, data, error }];
};

export const useFetchWithoutJwt = (url, opts) => {
  const [fetcher, result] = useFetcherWithoutJwtContext();

  const triggerFetch = useCallback(() => {
    return fetcher(url, opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, url, JSON.stringify(opts)]);

  return [triggerFetch, result];
};

export const useFetchOnMountWithoutJwt = (url, opts) => {
  const [triggerFetch, result] = useFetcherWithoutJwtContext();

  useEffect(() => {
    triggerFetch(url, opts);
  }, [triggerFetch, url, opts]);

  return result;
};

export const usePostWithoutJwt = (url, opts) => {
  const [fetcher, result] = useFetcherWithoutJwtContext();

  const triggerFetch = useCallback(
    (dataObj) => {
      return fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};
