import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';

import { HOME_ROUTE } from './routeConstants';
import { WalletContextProvider } from '@blank/common/WalletContext';
import Dapp from './Dapp';
import Home from './Home';
// import AdminPage from './Admin';
// import Spinner from '@blank/components/Spinner';

const Pages = () => (
  <Router>
    <WalletContextProvider>
      <Switch>
        <Route exact path={HOME_ROUTE}>
          {/* <AdminPage /> */}
          <Home />
        </Route>
        <Route>
          <Dapp />
        </Route>
      </Switch>
    </WalletContextProvider>
  </Router>
);

export default Pages;
