import React from 'react';
import { useMyCollection } from '../useCollection';
import { Text } from '@blank/atoms';
import { truncateAddress } from '@blank/utils';
import { isNil } from 'ramda';

const CollectionBreadcrumb = ({ collectionKey, isLast }) => {
  const collectionData = useMyCollection(collectionKey);

  const styleShort = {
    maxWidth: isLast ? 300 : 70,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  };
  return (
    <Text {...styleShort}>
      {!isNil(collectionData)
        ? collectionData?.name || truncateAddress(collectionKey)
        : ''}
    </Text>
  );
};

export default CollectionBreadcrumb;
