import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '../atoms';

const rotateKf = keyframes`
    0% { 
          transform: perspective(120px) rotateX(0deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
        } 50% { 
              transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
              -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
            } 100% { 
                  transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                  -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                }
`;

const FlipSpinner = styled(Box)`
  animation: ${rotateKf} 1.2s infinite ease-in-out;
`;

const Spinner = ({ size = 40, color = 'primary', ...props }) => (
  <FlipSpinner width={size} height={size} bg={color} {...props} />
);

export default Spinner;
