import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { omit } from 'ramda';

import { useFetchOnMount } from '../../../hooks/useFetch';
import { useLogin } from '../JwtContext';
// import RegisterModal from './RegisterModal';
import { useAccount } from '@blank/common/WalletContext/hooks';

const ProfileContext = createContext([]);

const initialState = {};

const SET_USER = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const ProfileProvider = memo(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLogin();

  const account = useAccount();
  const { data } = useFetchOnMount(`/api/users/${account}`);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_USER,
        payload: {
          address: account,
          register: !!data.address,
          ...data,
        },
      });
    }
  }, [account, data]);

  return (
    <ProfileContext.Provider value={[state, dispatch]}>
      {/* <RegisterModal /> */}
      {children}
    </ProfileContext.Provider>
  );
});

export const useUserInfo = () => {
  const [profileState] = useContext(ProfileContext);

  return omit(['cover'], profileState?.user);
};

export const useUserCover = () => {
  const [profileState] = useContext(ProfileContext);

  return profileState?.user?.cover;
};

export const useUpdateProfile = () => {
  const [, dispatch] = useContext(ProfileContext);

  return useCallback(
    (user) => {
      dispatch({
        type: UPDATE_USER,
        payload: user,
      });
    },
    [dispatch]
  );
};

export default ProfileContext;
