import { COLLECTION_TYPES } from '@blank/constants';
import {
  COLLAB_LOGIC_ADDRESS,
  SOLO_LOGIC_ADDRESS,
  GROUP_LOGIC_ADDRESS,
  VOLATILITY_ART_LOGIC_ADDRESS,
  VOLATILITY_COLECTION_V1_ADDRESS,
} from '@blank/constants/network';
import { web3 } from '@blank/common/constants';

export const getLogicAddressByCollectionType = (type) => {
  switch (type) {
    case COLLECTION_TYPES.collabs.type:
      return COLLAB_LOGIC_ADDRESS;
    case COLLECTION_TYPES.creations.type:
      return SOLO_LOGIC_ADDRESS;
    case COLLECTION_TYPES.groups.type:
      return GROUP_LOGIC_ADDRESS;
    case COLLECTION_TYPES.volatilityArt.type:
      return VOLATILITY_ART_LOGIC_ADDRESS;
    default:
      break;
  }
  return null;
};

// Solo and Solo v2 collection
export const createCreationMemoryData = async ({
  creator,
  name,
  symbol = '',
}) => {
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
      ],
    },
    [creator, name, symbol]
  );
  return data;
};

// Volatility migrate collection
export const createVolatilityMemoryData = async ({
  creator,
  name,
  symbol = '',
}) => {
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'v1Contract',
        },
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
      ],
    },
    [VOLATILITY_COLECTION_V1_ADDRESS, creator, name, symbol]
  );
  return data;
};

// Collab collection
export const createCollabMemoryData = async ({
  creator,
  name,
  symbol = '',
  royalty,
}) => {
  const BN = web3.utils.BN;
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
        {
          type: 'uint256',
          name: 'collabRoyalty',
        },
      ],
    },
    [creator, name, symbol, new BN(royalty)]
  );
  return data;
};

// Group collection
export const createGroupMemoryData = async ({
  creator,
  name,
  symbol = '',
  commission,
}) => {
  const BN = web3.utils.BN;
  const data = await web3.eth.abi.encodeFunctionCall(
    {
      name: 'initialize',
      type: 'function',
      inputs: [
        {
          type: 'address',
          name: 'creator',
        },
        {
          type: 'string',
          name: 'name',
        },
        {
          type: 'string',
          name: 'symbol',
        },
        {
          type: 'uint256',
          name: 'collabRoyalty',
        },
      ],
    },
    [creator, name, symbol, new BN(commission)]
  );
  return data;
};
