const customWalletModalUi = () => {
  const web3modalModalCardClass = document.getElementsByClassName(
    'web3modal-modal-card'
  );

  if (!web3modalModalCardClass || !web3modalModalCardClass[0]) {
    return;
  }

  const web3modalModalCard = web3modalModalCardClass[0];
  const footerElement = document.createElement('div');
  footerElement.innerHTML =
    '<span>If you’re new,</span><span><a href="https://ethereum.org/en/wallets/" target="_blank">Learn more</a> or <a href="https://metamask.io/" target="_blank"> Get Metamask</a></span>';
  footerElement.className = 'web3modal-modal-card-footer';
  web3modalModalCard.appendChild(footerElement);
};

export default customWalletModalUi;
