import React, { forwardRef, useCallback, useRef } from 'react';

import { Button, Input } from '../atoms';

const FileInput = forwardRef(
  ({ value, onChange, children, disabled, btnProps = {}, ...props }, ref) => {
    const inputRef = useRef(null);

    const handleChange = (e) => {
      const { files } = e.target;
      if (files.length === 0) return;

      const file = files.item(0);

      onChange(file);
    };

    const triggerFileInput = useCallback(() => {
      inputRef.current.click();
    }, []);

    if (!children) {
      return (
        <Input
          variant="original"
          type="file"
          onChange={handleChange}
          ref={ref}
          disabled={disabled}
          {...props}
        />
      );
    }

    const disabledProps = disabled && {
      onClick: () => {},
      cursor: 'default',
    };

    return (
      <Button
        variant="link"
        type="button"
        ref={ref}
        onClick={triggerFileInput}
        {...btnProps}
        {...disabledProps}
      >
        {children}
        <Input
          type="file"
          onChange={handleChange}
          hidden
          ref={inputRef}
          {...props}
        />
      </Button>
    );
  }
);

export default FileInput;
