import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Box } from '../atoms';
import Overlay from './Overlay';
import Spinner from './Spinner';

const modalRoot = document.getElementById('modal-root');

const LoadingOverlay = () => (
  <Overlay
    bg="rgba(255,255,255,0.8)"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner />
  </Overlay>
);

const useScrollLock = (lock) => {
  useEffect(() => {
    if (lock) {
      const scrollY = window.scrollY;
      Object.assign(document.body.style, {
        position: 'fixed',
        top: `-${scrollY}px`,
        left: 0,
        right: 0,
      });

      return () => {
        Object.assign(document.body.style, {
          position: '',
          top: '',
          left: '',
          right: '',
        });
        window.scrollTo(0, scrollY);
      };
    }
  }, [lock]);
};

const Modal = ({
  children,
  open = false,
  loading = false,
  onClickOutside,
  ...props
}) => {
  useScrollLock(open);

  const modal = open && (
    <Overlay
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      bg="rgba(0,0,0,0.5)"
      zIndex={10}
      onClick={onClickOutside}
      fontFamily="normal"
      fontSize={4}
      color="text"
      css={{
        userSelect: 'none',
      }}
    >
      <Box
        bg="background"
        position="relative"
        overflow="hidden"
        borderRadius={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        {...props}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        {loading && <LoadingOverlay />}
      </Box>
    </Overlay>
  );

  return ReactDOM.createPortal(modal, modalRoot);
};

export default Modal;
