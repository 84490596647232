import { ThemeProvider } from 'styled-components';
import React from 'react';

import { Flex } from './atoms';
import Pages from './pages';
import theme from './theme';

// Copy from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // log error to service
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex center fontSize={4} height="100vh">
          App crashed. Something went wrong!
        </Flex>
      );
    }

    return this.props.children;
  }
}

const App = () => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <Pages />
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
