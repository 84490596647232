import React from 'react';

import Modal from './Modal';
import Spinner from './Spinner';

const ProcessingModal = ({ children = 'Loading...', ...props }) => (
  <Modal
    width={500}
    height={442}
    p="32px 80px"
    fontWeight="bold"
    textAlign="center"
    {...props}
  >
    {children}
    <Spinner mx="auto" mt={4} />
  </Modal>
);

export default ProcessingModal;
