import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import Box from './Box';
import Icon from './Icon';

export { Icon, Box };

export const Flex = styled(Box)`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  ${(props) =>
    props.center &&
    `
		align-items:center;
		justify-content: center;
  `}
`;

export const Grid = styled(Box)`
  display: grid;
`;

export const Table = (props) => <Box as="table" {...props} />;

const ButtonWrapper = styled(Box)`
  &:hover {
    background: ${(props) => (props.disabled ? '#E9E9E9' : '')};
    box-shadow: ${(props) =>
      props.disabled ? '0px 2px 12px rgba(0,0,0,0.12);' : ''};
  }
  background: ${(props) => (props.disabled ? '#E9E9E9' : '')};
`;

export const Button = (props) => (
  <ButtonWrapper
    as="button"
    cursor="pointer"
    fontSize="inherit"
    fontFamily="normal"
    variantsKey="button"
    {...props}
  />
);
Button.defaultProps = { variant: 'primary' };

export const Input = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="input"
    fontSize="inherit"
    fontFamily="normal"
    variantsKey="input"
    {...props}
  />
));
Input.defaultProps = { variant: 'primary' };

export const Textarea = forwardRef((props, ref) => (
  <Input ref={ref} as="textarea" rows="4" {...props} />
));

export const Image = (props) => <Box as="img" {...props} />;

export const Video = (props) => <Box as="video" {...props} />;
export const Audio = (props) => <Box as="audio" {...props} />;

export const VideoControls = forwardRef((props, ref) => (
  <Box ref={ref} as="video" {...props} />
));
export const AudioControls = forwardRef((props, ref) => (
  <Box as="audio" ref={ref} {...props} />
));

export const Picture = ({ children, ...props }) => (
  <picture>
    {children}
    <Image {...props} />
  </picture>
);

export const Text = (props) => <Box as="span" {...props} />;

export const Anchor = ({ original, ...props }) => {
  const restyleProps = !original && {
    color: 'inherit',
    textDecoration: 'none',
  };

  return <Box as="a" {...restyleProps} {...props} />;
};

export const Link = (props) => <Anchor as={RouterLink} {...props} />;
