import { useCallback, useEffect } from 'react';
import { useContractAdder } from '@blank/common/WalletContext/hooks/useContract';
import BlankSoloCollection from '@blank/contracts/BlankSoloCollection.json';
import BlankCollabCollection from '@blank/contracts/BlankCollabCollection.json';
import BlankGroupCollection from '@blank/contracts/BlankGroupCollection.json';
import BlankVolatilityArtCollection from '@blank/contracts/BlankVolatilityArtCollection.json';
import BlankNFTAbi from '@blank/contracts/BlankNFT.json';
import { addressEquals } from '@blank/utils';
import {
  BLANKNFT_ADDRESS,
  VOLATILITY_COLECTION_V1_ADDRESS,
} from '@blank/constants/network';
import { COLLECTION_TYPES } from '@blank/constants';
import { useVolatilityV2Address } from '@blank/pages/shared/VolatilityContext';

const useAddCollectionContract = (collectionAddress, type) => {
  const addContract = useContractAdder();
  const VOLATILITY_COLECTION_V2_ADDRESS = useVolatilityV2Address();

  const addContractAction = useCallback(() => {
    if (!collectionAddress) return;
    if (addressEquals(collectionAddress, BLANKNFT_ADDRESS)) {
      addContract(BlankNFTAbi, BLANKNFT_ADDRESS);
    } else if (
      [
        VOLATILITY_COLECTION_V1_ADDRESS,
        VOLATILITY_COLECTION_V2_ADDRESS,
      ].includes(collectionAddress.toLowerCase())
    ) {
      addContract(BlankVolatilityArtCollection, collectionAddress);
    } else if (type === COLLECTION_TYPES.collabs.key) {
      addContract(BlankCollabCollection, collectionAddress);
    } else if (type === COLLECTION_TYPES.groups.key) {
      addContract(BlankGroupCollection, collectionAddress);
    } else {
      addContract(BlankSoloCollection, collectionAddress);
    }
  }, [VOLATILITY_COLECTION_V2_ADDRESS, addContract, collectionAddress, type]);

  return addContractAction;
};

export const usePreCollectionContract = () => {
  const addContract = useContractAdder();

  const addContractAction = useCallback(
    (collectionAddress, type) => {
      if (!collectionAddress) return;
      if (addressEquals(collectionAddress, BLANKNFT_ADDRESS)) {
        addContract(BlankNFTAbi, BLANKNFT_ADDRESS);
      } else if (
        addressEquals(collectionAddress, VOLATILITY_COLECTION_V1_ADDRESS)
      ) {
        addContract(BlankVolatilityArtCollection, collectionAddress);
      } else if (type === COLLECTION_TYPES.collabs.key) {
        addContract(BlankCollabCollection, collectionAddress);
      } else if (type === COLLECTION_TYPES.groups.key) {
        addContract(BlankGroupCollection, collectionAddress);
      } else {
        addContract(BlankSoloCollection, collectionAddress);
      }
    },
    [addContract]
  );

  return addContractAction;
};

export const useAddCollectionContractOnMount = (collectionAddress, type) => {
  const addCollectionContract = useAddCollectionContract(
    collectionAddress,
    type
  );
  useEffect(() => {
    if (!collectionAddress) return;
    addCollectionContract();
  }, [addCollectionContract, collectionAddress, type]);
};

export default useAddCollectionContract;
