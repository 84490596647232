import React from 'react';
import { Text, Flex } from '@blank/atoms';

const PageNotFound = () => {
  return (
    <Flex alignItems="center" flexDirection="column" pt={100} width="100%">
      <Text
        alignItems="center"
        fontSize={48}
        fontWeight="bold"
        color="text"
        mb={30}
        textAlign="center"
      >
        404
      </Text>
      <Text
        alignItems="center"
        fontSize={16}
        fontWeight={600}
        color="text"
        mb={30}
        textAlign="center"
      >
        Sorry! Page not found.
      </Text>
    </Flex>
  );
};

export default PageNotFound;
