import { Redirect } from 'react-router-dom';
import React from 'react';

import { ADMIN_ROUTE } from '../routeConstants';
import { Flex, Text, Box } from '../../atoms';
import { useAccount } from '@blank/common/WalletContext/hooks';
import ConnectButton from '@blank/common/WalletContext/shared/ConnectButton';
import Layout from '../shared/Layout';
import { BLANK_ADMIN } from '@blank/constants/network';

const Home = ({ loading }) => {
  const account = useAccount();
  // const history = useHistory();

  if (account && account === BLANK_ADMIN) {
    return <Redirect to={ADMIN_ROUTE} />;
  }

  return (
    <Layout home>
      <Flex center flexDirection="column" width="100%" mb={45}>
        <Text
          fontSize={72}
          fontWeight="bold"
          mb={45}
          fontFamily="special"
          textAlign="center"
          lineHeight="101.4%"
        >
          blank admin
        </Text>
        <Box>
          {account && (
            <Text fontSize="28px" fontWeight="400">
              You are not admin
            </Text>
          )}
          {!account && <ConnectButton ml="15px" />}
        </Box>
      </Flex>
    </Layout>
  );
};

export default Home;
