import { useParams } from 'react-router-dom';

// import COLLECTIONS from '@blank/constants/collections';
import { useFetchOnMount } from '@blank/hooks/useFetch';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { addressEquals } from '@blank/utils';
import { usePreCollectionContract } from '@blank/hooks/useAddCollectionContract';

export const useMyCollection = (address, addcontract) => {
  const account = useAccount();
  const { data } = useFetchOnMount(`/api/collections/${address}`);
  const addPreCollectionContract = usePreCollectionContract();

  if (data?.collection && addcontract) {
    addPreCollectionContract(address, data?.type);
  }

  const isOwner =
    !!account &&
    data?.creator?.address &&
    addressEquals(data?.creator?.address, account);

  return {
    key: data?.collection,
    type: data?.type,
    name: data?.metadata?.name,
    isOwner,
    creator: data?.creator,
    description: data?.metadata?.description,
    background: data?.metadata?.background,
    image: data?.metadata?.image,
    totalParticipants: data?.total_participants
      ? +data?.total_participants
      : null,
    metadata: data?.metadata,
    royalty: data?.metadata?.royalty,
    wrongCollection: data && !data.collection,
  };
};

const useCollection = () => {
  const { collectionKey } = useParams();
  // const collection = COLLECTIONS[collectionKey];
  const myCollection = useMyCollection(collectionKey, true);

  // return collection || myCollection;
  return myCollection;
};

export const useCollectionForMint = (address) => {
  const account = useAccount();
  const { data } = useFetchOnMount(`/api/collections/${address}`);

  return {
    isOwner:
      !!account &&
      data?.creator?.address &&
      addressEquals(data?.creator?.address, account),
    ...data,
  };
};

export default useCollection;
