import React, { useMemo } from 'react';

import { Box, Link } from '../../../atoms';
import { useRouteMatch } from 'react-router-dom';
import CollectionImage from '../CollectionImage';
import Overlay from '@blank/components/Overlay';
import { truncateAddress } from '../../../utils';
import { WHITE_WALL_ROUTE } from '../../routeConstants';
import { VOLATILITY_COLECTION_V1_ADDRESS } from '@blank/constants/network';
// import CollectionImage from '../CollectionImage';
// import Overlay from '../../../components/Overlay';

export const ContentWithImage = ({
  collection,
  children,
  imagePropsFront,
  imagePropsBack,
}) => {
  return (
    <>
      <CollectionImage
        collection={collection}
        id="hovered"
        width="100%"
        position="absolute"
        top="0px"
        left="0px"
        // opacity={0.25}
        transition="top 1s, right 1s"
        {...imagePropsBack}
        isBack
      />
      <CollectionImage
        collection={collection}
        width="100%"
        position="relative"
        left="-9px"
        top="-6px"
        {...imagePropsFront}
      />
      <Overlay display="flex" alignItems="center" justifyContent="center">
        {children}
      </Overlay>
    </>
  );
};

export const ContentWithColor = ({ collection, file }) => {
  const background =
    collection.background && collection.background !== 'undefined'
      ? collection.background
      : 'white';
  const src = useMemo(() => (file ? URL.createObjectURL(file) : file), [file]);
  const image =
    collection.image_small ||
    collection.image_preview ||
    collection.image ||
    src;
  const backgroundStyle = !!image
    ? {
        background: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    : {
        bg: background,
      };

  return (
    <>
      <Box
        position="absolute"
        top="0px"
        left="0px"
        // bg={background}
        width="100%"
        height="90%"
        {...backgroundStyle}
      >
        <Box bg="black" width="100%" height="100%" opacity={0.5} />
      </Box>
      <Box
        position="absolute"
        top="0px"
        width="100%"
        height="100%"
        filter="drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))"
      >
        <Box
          // bg={background}
          width="100%"
          height="100%"
          clipPath="polygon(0 0, 90% 10%, 90% 100%, 0 90%)"
          {...backgroundStyle}
        />
      </Box>
    </>
  );
};

const CollectionItemContent = ({
  collection,
  wrapProps = {},
  noImage,
  children,
  width = 200,
  height = 200,
  onClick = () => null,
}) => {
  const { creator, totalParticipants, collectionKey } = collection;
  return (
    <Box {...wrapProps} onClick={() => onClick(collection)}>
      <Box position="relative" mb={12} width={width} height={height}>
        {noImage ? (
          <ContentWithColor collection={collection} />
        ) : (
          <ContentWithImage collection={collection}>
            {children}
          </ContentWithImage>
        )}
      </Box>
      <Box fontWeight={500} fontSize={14} mb={14} textAlign="left">
        <Box
          mb="2px"
          color={
            collectionKey === VOLATILITY_COLECTION_V1_ADDRESS ? '#FFD700' : ''
          }
        >
          {collection.name}
        </Box>
        {creator && (
          <Box>
            {`by `}
            <Link to={WHITE_WALL_ROUTE.replace(':account', creator.address)}>
              @{creator.username || truncateAddress(creator.address)}
            </Link>
            {` and ${totalParticipants - 1} ${
              totalParticipants - 1 > 1 ? 'others' : 'other'
            }`}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CollectionItem = ({ noLink, type, collection, ...props }) => {
  const match = useRouteMatch();
  if (noLink) {
    return <CollectionItemContent collection={collection} {...props} />;
  }

  const { collectionType = 'collab' } = props;
  return (
    <Link
      to={`${match.url}${type ? `/${type}/` : '/'}${
        collection.collectionKey
      }/${collectionType}`}
    >
      <CollectionItemContent collection={collection} {...props} />
    </Link>
  );
};

export default CollectionItem;
