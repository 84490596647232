import React from 'react';

import { Anchor, Button, Text } from '@blank/atoms';
import { ETHERSCAN_DOMAIN } from '@blank/constants/network';
import Modal from '@blank/components/Modal';

const TransactionSubmittedModal = ({
  open,
  transactionHash,
  onClose,
  ...props
}) => {
  return (
    <Modal
      open={open}
      alignItems="center"
      p="32px 80px"
      width={547}
      height={442}
      {...props}
    >
      <Text
        fontWeight="bold"
        fontSize={24}
        lineHeight="140%"
        color="text"
        mb={20}
      >
        Transaction Submitted
      </Text>
      <Text fontSize={18} lineHeight="170%" color="text" mb={48}>
        Your transaction is being processed.{' '}
        <Anchor
          original
          href={`${ETHERSCAN_DOMAIN}/${transactionHash}`}
          target="_blank"
          textDecoration="underline"
          color="text"
        >
          View tx
        </Anchor>
      </Text>
      <Button variant="link" onClick={onClose}>
        OK
      </Button>
    </Modal>
  );
};

export default TransactionSubmittedModal;
