import { curry, startsWith } from 'ramda';
import { COLLECTION_COLORS } from '@blank/constants';
import { isEmpty } from 'ramda';
import { matchPath } from 'react-router-dom';

export const truncateAddress = (address = '') => {
  const length = address.length;
  const head = address.substring(0, 6);
  const tail = address.substring(length - 4, length);

  return `${head}...${tail}`;
};

export const addressEquals = curry(
  (addr1 = '', addr2 = '') =>
    addr1 && addr2 && addr1.toLowerCase() === addr2.toLowerCase()
);

export const getFileType = (file) => file && file.type.split('/')[0];

export const getRandomColor = () => {
  const index = Math.floor(Math.random() * 7);
  return COLLECTION_COLORS[index];
};

export const checkExternalUrl = (url) => {
  if (!url) return false;
  return startsWith('http://', url) || startsWith('https://', url);
};

export const convertBreakLine = (data = '') => {
  if (!data) return '';
  try {
    let result = JSON.stringify(data) || '';
    result = result.replaceAll('\\n', '</br>');
    return JSON.parse(result);
  } catch (error) {
    return data;
  }
};

export const getHighestBid = (bids = []) => {
  if (isEmpty(bids)) return 0;
  let highestBid = 0;
  bids.forEach((bid) => {
    if (parseInt(bid.price) > highestBid) {
      highestBid = parseInt(bid.price);
    }
  });
  return highestBid;
};

export const getMatchedRouteAndPath = (routeConfigs, pathname) => {
  for (let item of routeConfigs) {
    const matchResult = matchPath(pathname, item);
    if (matchResult) {
      return {
        ...item,
        ...matchResult,
      };
    }
  }
};
