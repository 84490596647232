import React from 'react';

import { Button } from '@blank/atoms';
import { useConnectWallet } from '@blank/common/WalletContext/hooks';

const ConnectButton = (props) => {
  const connectWallet = useConnectWallet();

  return (
    <Button {...props} variant="secondary" onClick={connectWallet}>
      Connect
    </Button>
  );
};

export default ConnectButton;
