import React, { useState } from 'react';
import Modal from '@blank/components/Modal';
import { Flex, Text, Button } from '@blank/atoms';
import { Form, Formik } from 'formik';
import SearchInviteField from './shared/SearchInviteField';
import * as Yup from 'yup';
import { useAccount } from '@blank/common/WalletContext/hooks';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import { usePost } from '@blank/hooks/usePost';
import { createHashForInvite } from '@blank/utils/ethereum.util';
import Spinner from '@blank/components/Spinner';
import InviteSuccessModal from './InviteSuccessModal';

const InvitesSchema = (type) =>
  Yup.object().shape({
    invites: Yup.array().min(1),
  });

const TitleText = ({ title }) => (
  <Text
    fontWeight="bold"
    fontSize={24}
    lineHeight="140%"
    mb={26}
    width="100%"
    textAlign="center"
  >
    {title}
  </Text>
);

const InvitesForm = ({ collectionAddress, onClose }) => {
  const [inviting, setInviting] = useState(false);
  const [invited, setInvited] = useState(false);
  const account = useAccount();
  const web3 = useWeb3();
  const [createInvitations] = usePost(
    '/api/collections/collabs/invitations',
    {}
  );

  return (
    <Formik
      initialValues={{
        invites: [],
      }}
      validationSchema={InvitesSchema}
      onSubmit={async (values) => {
        setInviting(true);
        try {
          // create invitations
          const invitationsInfos = await createInvitations({
            collection: collectionAddress,
            sender: account,
            receivers: values.invites
              ? values.invites.map((user) => user.address)
              : [],
          });

          // get hash for sign
          const hashInvite = await createHashForInvite(invitationsInfos);

          // get signature
          const signature = await web3.eth.personal.sign(
            hashInvite,
            account,
            ''
          );

          // Update invitations
          await createInvitations({
            nonce: +invitationsInfos.nonce,
            collection: collectionAddress,
            signature,
          });
          setInvited(true);
        } catch (error) {
          console.error('Failed to invites other members: ', error);
        }
        // onClose();
        setInviting(false);
      }}
    >
      {({ isValid, dirty, submitForm, submitDisabled, values }) =>
        invited ? (
          <InviteSuccessModal open invites={values.invites} onClose={onClose} />
        ) : (
          <Modal open width={600} p="4.6rem">
            <Flex
              as={Form}
              center
              width="100%"
              flexDirection="column"
              onKeyPress={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault();
                }
              }}
            >
              <TitleText title="Invite Members" />
              <Text fontSize={16} lineHeight="100%" mb={30}>
                Each invitation will last for 7 days.
              </Text>
              <SearchInviteField
                fieldName="invites"
                collectionKey={collectionAddress}
              />
              {inviting ? (
                <Spinner mx="auto" mt={4} />
              ) : (
                <>
                  <Button
                    variant="link"
                    disabled={submitDisabled || !isValid || !dirty}
                    onClick={() => submitForm()}
                    type="button"
                    mx="12px"
                    fontWeight="bold"
                    mb={20}
                  >
                    Invite
                  </Button>
                  <Text fontSize={12} color="primaryNormalLight">
                    Or you can{' '}
                    <Button variant="link" color="text" onClick={onClose}>
                      Skip
                    </Button>{' '}
                    and do this later
                  </Text>
                </>
              )}
            </Flex>
          </Modal>
        )
      }
    </Formik>
  );
};

export default InvitesForm;
