import React from 'react';
import { Text } from '@blank/atoms';
import useWhiteListMember from '@blank/hooks/useWhiteListMember';
import { isNil } from 'ramda';

const StudioBreadcrumb = ({ isLast }) => {
  // const styleShort = {
  //   maxWidth: 25,
  //   overflow: 'hidden',
  //   display: 'block',
  //   textOverflow: 'ellipsis',
  // };
  const isWhiteList = useWhiteListMember();
  return (
    <Text>
      {!isNil(isWhiteList) && (isWhiteList ? 'MY STUDIO' : 'MY NFTs')}
    </Text>
  );
};

export default StudioBreadcrumb;
