import { useField, useFormikContext } from 'formik';
import React, { useCallback, useRef } from 'react';

import InputWrapper from './InputWrapper';
import useFormikFocusFirstError from '../hooks/useFormikFocusFirstError';

const FormCustom = ({
  Component,
  inline,
  name,
  label,
  inputRef,
  wrapperProps,
  ...props
}) => {
  const [{ value }] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = useCallback((value) => setFieldValue(name, value), [
    name,
    setFieldValue,
  ]);

  const ref = useRef();
  const fieldRef = inputRef || ref;
  useFormikFocusFirstError({ fieldRef, name });

  const input = (
    <Component
      ref={fieldRef}
      value={value}
      onChange={handleChange}
      {...props}
    />
  );

  return inline ? (
    input
  ) : (
    <InputWrapper name={name} label={label} {...wrapperProps}>
      {input}
    </InputWrapper>
  );
};

export default FormCustom;
