import React from 'react';

import { useUserInfo, useUserCover } from './ProfileContext';
import User from './User';

const CurrentUserAvatar = (props) => {
  const user = useUserInfo();

  return <User.Avatar user={user} {...props} objectFit="cover" />;
};
const CurrentUserName = (props) => {
  const user = useUserInfo();

  return <User.Name user={user} {...props} />;
};
const CurrentCover = (props) => {
  const cover = useUserCover();

  return <User.Cover cover={cover} {...props} />;
};

export default {
  Avatar: CurrentUserAvatar,
  Name: CurrentUserName,
  Cover: CurrentCover,
};
