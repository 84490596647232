import React from 'react';
import { any, prop } from 'ramda';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { Anchor, Box, Flex, Link } from '../../../../atoms';

const NavItem = ({ label, href, external, pages = [], ...props }) => {
  const location = useLocation();
  const matched = any((path) =>
    prop('isExact')(matchPath(location.pathname, path))
  )(pages);

  return !href ? (
    <Box {...props}>{label}</Box>
  ) : external ? (
    <Anchor href={href} target="blank" {...props}>
      {label}
    </Anchor>
  ) : (
    <Link to={href} color={matched ? 'text' : 'textHeader'} {...props}>
      {label}
    </Link>
  );
};
const Navigator = ({ navs, gap = 28, ...props }) => {
  return (
    <Flex
      alignItems="center"
      fontWeight="500"
      fontSize={14}
      whiteSpace="nowrap"
      {...props}
    >
      {navs.map((nav, index) => (
        <NavItem {...nav} ml={index > 0 ? gap : 0} />
      ))}
    </Flex>
  );
};

export default Navigator;
