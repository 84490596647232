import React from 'react';
import Modal from '@blank/components/Modal';
import { Flex, Text, Textarea, Button } from '@blank/atoms';
import { Form, useFormikContext } from 'formik';
import FormInput from '@blank/components/FormInput';
import ProcessingModal from '@blank/components/ProcessingModal';
import { COLLECTION_TYPES } from '@blank/constants';
import { ContentWithColor } from '../shared/CollectionGrid/CollectionItem';
import FormCustom from '@blank/components/FormCustom';
import FileInput from '@blank/components/FileInput';
import FormFieldWithExtend from '@blank/components/FormFieldWithExtend';

const TitleText = ({ title }) => (
  <Text
    fontWeight="bold"
    fontSize={24}
    lineHeight="140%"
    mb={40}
    width="100%"
    textAlign="center"
  >
    {title}
  </Text>
);

const CollectionInfo = ({ title }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { type, file, name } = values;

  return (
    <>
      <TitleText title={title} />
      <Flex center position="relative" mb={18} width={100} height={100}>
        <ContentWithColor
          file={file}
          collection={{ background: values.background }}
        />
      </Flex>
      <Flex
        fontWeight={600}
        fontSize={12}
        lineHeight="160%"
        color="primaryLight"
        flexDirection="column"
      >
        <Flex>
          <FormCustom
            name="file"
            btnProps={{
              color: 'text',
              textDecoration: 'underline',
              mr: '5px',
            }}
            Component={FileInput}
          >
            Upload cover
          </FormCustom>{' '}
          or use a{' '}
          <Text
            color="text"
            ml="5px"
            textDecoration="underline"
            fontWeight="normal"
            cursor="pointer"
            onClick={() => setFieldValue('file', null)}
          >
            default color
          </Text>
        </Flex>
        {values.file && errors?.file && (
          <Text
            fontSize="1rem"
            color="red"
            width="100%"
            mb={24}
            textAlign="center"
          >
            {errors?.file}
          </Text>
        )}
      </Flex>
      <FormInput
        name="name"
        placeholder="Title (eg: Untitled) *"
        width="100%"
        textAlign={!!name ? 'center' : 'left'}
        wrapperProps={{
          width: '100%',
        }}
      />
      <FormInput
        Component={Textarea}
        name="description"
        placeholder="Description"
        width="100%"
        borderRadius={22}
        lineHeight="120%"
        wrapperProps={{
          width: '100%',
        }}
      />
      <FormInput
        name="symbol"
        placeholder="Symbol *"
        width="100%"
        textAlign="left"
        wrapperProps={{
          width: '100%',
        }}
      />
      {type === COLLECTION_TYPES.collabs.type && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDirection="row"
          mb={50}
        >
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text
              textAlign="left"
              fontWeight="bold"
              fontSize={24}
              lineHeight="100%"
              mb="10px"
            >
              Communal Royalties
            </Text>
            <Text
              textAlign="left"
              fontWeight="normal"
              fontSize={14}
              lineHeight="160%"
            >
              Shared equally by all participating artists.
            </Text>
          </Flex>
          <FormFieldWithExtend
            name="royalties"
            width="100%"
            extendText="%"
            isBefore={false}
            extendProps={{
              top: 12,
            }}
            wrapperProps={{
              width: 95,
            }}
            wrapperFieldProps={{
              mb: '0px',
            }}
            pr={40}
            placeholder="10"
          />
        </Flex>
      )}
      {type === COLLECTION_TYPES.groups.type && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDirection="row"
          mb={50}
        >
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text
              textAlign="left"
              fontWeight="bold"
              fontSize={24}
              lineHeight="100%"
              mb="10px"
            >
              Commission for Admin *
            </Text>
            <Text
              textAlign="left"
              fontWeight="normal"
              fontSize={14}
              lineHeight="160%"
            >
              Received by Collection Admin
            </Text>
          </Flex>
          <FormFieldWithExtend
            name="commission"
            width="100%"
            extendText="%"
            isBefore={false}
            extendProps={{
              top: 12,
            }}
            wrapperProps={{
              width: 95,
            }}
            wrapperFieldProps={{
              mb: '0px',
            }}
            pr={40}
            placeholder="10"
          />
        </Flex>
      )}
    </>
  );
};

const CollectionFormModal = ({ inProcess, submitDisabled, onClose }) => {
  const { values, isValid, dirty, submitForm } = useFormikContext();
  const { type } = values;
  const collectionsType = COLLECTION_TYPES[type];
  const title = `Launch ${collectionsType?.title} Smart Contract`;

  return inProcess ? (
    <ProcessingModal open>{title}</ProcessingModal>
  ) : (
    <Modal open onClickOutside={() => onClose()} width={600} p="4.6rem">
      <Flex
        as={Form}
        center
        width="100%"
        flexDirection="column"
        onKeyPress={(keyEvent) => {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }}
      >
        <CollectionInfo title={title} />
        <Button
          variant="link"
          disabled={submitDisabled || !isValid || !dirty}
          onClick={() => submitForm()}
          type="button"
          mx="12px"
        >
          Launch
        </Button>
      </Flex>
    </Modal>
  );
};

export default CollectionFormModal;
