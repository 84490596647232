import { useContext, useEffect, useCallback } from 'react';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import WalletContext from '@blank/common/WalletContext';

export const useContractAdder = () => {
  const web3 = useWeb3();

  const { contractInfos, setContractInfos } = useContext(WalletContext);

  return useCallback(
    (abi, address, name) => {
      try {
        if (!web3) {
          return;
        }
        const contract = new web3.eth.Contract(abi, address);
        if (!contractInfos[name || address]) {
          setContractInfos({
            ...contractInfos,
            [name || address]: contract,
          });
        }
      } catch (err) {
        console.warn('Error when add contract infors: ', err);
      }
    },
    [contractInfos, setContractInfos, web3]
  );
};

export const useContractOnMount = (abi, address, name) => {
  const addContract = useContractAdder();
  const web3 = useWeb3();

  useEffect(() => {
    if (!web3 || !address) return;
    addContract(abi, address, name);
  }, [abi, addContract, address, name, web3]);
};
