import { useEffect } from 'react';

const useDisableEthereumReload = () => {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
  }, []);
};

export default useDisableEthereumReload;
