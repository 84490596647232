import React, { useCallback, useEffect, useState } from 'react';

import css from '@styled-system/css';

import { Box, Flex, Icon, Text } from '../atoms';

const CopyButton = ({ text, textDisPlay, showIcon, ...props }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    window.navigator.clipboard.writeText(text).then(() => setCopied(true));
  }, [text]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  if (copied) {
    return (
      <Flex alignItems="center" position="relative" {...props}>
        <Icon name="check" pr="2px" />
        <Text {...props}>Copied</Text>
      </Flex>
    );
  }

  return (
    <Box
      css={css({
        ':hover': {
          color: 'text',
        },
      })}
      cursor="pointer"
      {...props}
      onClick={handleClick}
    >
      {!showIcon && <Text {...props}>{textDisPlay}</Text>}
      {showIcon && <Icon name="copy" size="0.9em" />}
    </Box>
  );
};

export default CopyButton;
