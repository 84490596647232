import React from 'react';
import { Text } from '@blank/atoms';

const ExploreBreadcrumb = ({ isLast }) => {
  // const styleShort = {
  //   maxWidth: 25,
  //   overflow: 'hidden',
  //   display: 'block',
  //   textOverflow: 'ellipsis',
  // };
  return <Text>EXPLORE</Text>;
};

export default ExploreBreadcrumb;
