import { propEq, findIndex, update, filter } from 'ramda';
import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useCallback,
} from 'react';

import { TRANSACTION_STATUS } from '../constants';
import {
  NEW_TRANSACTION,
  UPDATE_TRANSACTION,
  COMPLETE_TRANSACTION,
  REMOVE_TRANSACTION,
  TRANSACTION_STORE_KEY,
} from './constants';
import useSubcribeTransactionStatus from './useSubcribeTransactionStatus';
import useReducerWithLocalStorage from '../hooks/useReducerWithLocalStorage';
import { useAccount } from '../WalletContext/hooks';
import useTransactionFromLocalstorage from './useTransactionFromLocalstorage';

const TransactionContext = createContext([]);

// const initialState = {
//   transactions: [],
// };

const reducer = (state, action) => {
  switch (action.type) {
    case NEW_TRANSACTION:
      return {
        ...state,
        transactions: [...(state.transactions || []), action.payload],
      };
    case UPDATE_TRANSACTION:
      const currentIndex = findIndex(propEq('hash', action.payload.hash))(
        state.transactions
      );

      return {
        ...state,
        transactions: update(currentIndex, action.payload, state.transactions),
      };
    case COMPLETE_TRANSACTION: {
      const { transaction, status } = action.payload;
      const currentIndex = findIndex(propEq('hash', transaction.hash))(
        state.transactions
      );

      return {
        ...state,
        transactions: update(
          currentIndex,
          {
            ...state.transactions[currentIndex],
            status: status,
          },
          state.transactions
        ),
      };
    }
    case REMOVE_TRANSACTION: {
      return {
        ...state,
        transactions: filter((tx) => tx.hash !== action.payload.hash)(
          state.transactions
        ),
      };
    }
    default:
      return state;
  }
};

export const TransactionProvider = memo(({ children }) => {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const account = useAccount();
  const oldTransactions = useTransactionFromLocalstorage();
  const [state, dispatch] = useReducerWithLocalStorage({
    initializerArg: {
      transactions: oldTransactions || [],
    },
    key: `${TRANSACTION_STORE_KEY}_${account}`,
    reducer,
  });
  const subcribeTransactionStatus = useSubcribeTransactionStatus(dispatch);

  useEffect(() => {
    oldTransactions.map((tx) => {
      if (tx?.hash) {
        subcribeTransactionStatus(tx);
      }
      return tx;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransactionContext.Provider value={[state, dispatch]}>
      {children}
    </TransactionContext.Provider>
  );
});

// export const useCheckTransaction = ({ status, receipt }) => {
//   const [{ transactions = [] } = {}] = useContext(TransactionContext);
//   const [pending, setPending] = useState(false);

//   const transactionFound = !!find(equals(receipt?.transactionHash))(
//     transactions
//   );

//   useEffect(() => {
//     if (status === TRANSACTION_STATUS.pending) {
//       setPending(true);
//     }
//     if (status === TRANSACTION_STATUS.error) {
//       setPending(false);
//     }
//     if (transactionFound) {
//       setPending(false);
//     }
//   }, [status, transactionFound]);

//   return {
//     pending,
//     success: transactionFound,
//     error: status === TRANSACTION_STATUS.error,
//   };
// };

export const useTransactionsList = () => {
  const [state] = useContext(TransactionContext);

  return state.transactions || [];
};

export const useTransactionNumberByType = (
  type = TRANSACTION_STATUS.pending
) => {
  const [state] = useContext(TransactionContext);
  const transactionsList = state.transactions || [];
  const transactions = filter((tx) => tx.status === type)(transactionsList);
  return transactions.length || 0;
};

export const useTransactionByTypeAndReloadType = (
  type = TRANSACTION_STATUS.success,
  reloadTypes = []
) => {
  const [state] = useContext(TransactionContext);
  const transactionsList = state?.transactions || [];
  const transactions = filter(
    (tx) =>
      tx.status === type &&
      tx.reloadInfo?.type &&
      reloadTypes.includes(tx.reloadInfo?.type)
  )(transactionsList);
  return transactions || [];
};

export const useAddTransactionToList = () => {
  const [, dispatch] = useContext(TransactionContext);
  const subcribeTransactionStatus = useSubcribeTransactionStatus(dispatch);

  const addTransactionToList = useCallback(
    (transaction) => {
      subcribeTransactionStatus(transaction);
      dispatch({
        type: NEW_TRANSACTION,
        payload: transaction,
      });
    },
    [dispatch, subcribeTransactionStatus]
  );

  return addTransactionToList;
};

export const useUpdateTransactionToList = () => {
  const [, dispatch] = useContext(TransactionContext);

  const updateTransactionToList = useCallback(
    (transaction) => {
      dispatch({ type: UPDATE_TRANSACTION, payload: transaction });
    },
    [dispatch]
  );

  return updateTransactionToList;
};

export default TransactionContext;
