import { useAccount } from '@blank/common/WalletContext/hooks';
import { useFetchOnMount } from '@blank/hooks/useFetch';

const useWhiteListMember = (params) => {
  const currentAccount = useAccount();
  const account = params?.account || currentAccount;
  const { data } = useFetchOnMount(`/api/whitelist/check?user=${account}`);
  console.log(data, 'fdfd');

  return data;
};

export default useWhiteListMember;
