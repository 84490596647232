import { useReducer } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const useReducerWithLocalStorage = ({ initializerArg, key, reducer }) => {
  const [localStorageState, setLocalStorageState] = useLocalStorageState(
    key,
    initializerArg
  );

  return useReducer(
    (state, action) => {
      const newState = reducer(state, action);
      setLocalStorageState(newState);
      return newState;
    },
    { ...localStorageState }
  );
};

export default useReducerWithLocalStorage;
