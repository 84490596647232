import React, { createContext, useCallback, useState } from 'react';
import { HOME_ROUTE } from '@blank/pages/routeConstants';

import { useHistory } from 'react-router-dom';

import Web3 from 'web3';

import Web3Modal from 'web3modal';
import { getProviderOptions } from './utils';
import { useEffect } from 'react';
import { CONTRACT_INFOS } from '@blank/contracts';
import { NETWORK, NETWORK_ID, CHAIN_ID } from '@blank/constants/network';
import useDisableEthereumReload from './hooks/useDisableEthereumReload';
import customWalletModalUi from './utils/customWalletModalUi';
// import { WALLET_CONNECT } from '@blank/constants/wallets';

const WalletContext = createContext({});

const INITIAL_DATA_STATE = () => ({
  address: null,
  web3: null,
  provider: null,
  connected: false,
  chainId: CHAIN_ID,
  networkId: NETWORK_ID,
  isWrongNetwork: false,
  cachedProvider: localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER'),
});

// Init contract
const initContractInfos = (web3) => {
  const contracts = {};
  CONTRACT_INFOS.map((info) => {
    const { abi, address, name } = info;
    contracts[name] = new web3.eth.Contract(abi, address);
    return info;
  });
  return contracts;
};

// Init web3
const initWeb3 = (provider) => {
  const web3 = new Web3(provider);

  // web3.eth.extend({
  //   methods: [
  //     {
  //       name: 'chainId',
  //       call: 'eth_chainId',
  //       outputFormatter: web3.utils.hexToNumber,
  //     },
  //   ],
  // });

  const contracts = initContractInfos(web3);

  return {
    web3,
    contracts,
  };
};

export const WalletContextProvider = ({ children }) => {
  const history = useHistory();
  useDisableEthereumReload();
  const [walletInfo, setWalletInfo] = useState(INITIAL_DATA_STATE);
  const [contractInfos, setContractInfos] = useState({});

  const web3Modal = new Web3Modal({
    network: NETWORK,
    cacheProvider: true,
    providerOptions: getProviderOptions(),
  });

  const resetWallet = useCallback(async () => {
    const { web3 } = walletInfo;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();
    await web3Modal.resetState();
    await setWalletInfo(INITIAL_DATA_STATE);
  }, [walletInfo, web3Modal]);

  const subscribeProvider = useCallback(
    async (provider) => {
      if (!provider.on) {
        return;
      }
      provider.on('close', async () => {
        await resetWallet();
      });
      provider.on('accountsChanged', async (accounts) => {
        window.location.reload();
        // setWalletInfo({ ...walletInfo, address: accounts[0] });
      });
      // provider.on('chainChanged', async (chainId) => {
      //   console.log('===chainId', chainId)
      //   window.location.reload();
      //   // const { web3 } = walletInfo;
      //   // const networkId = await web3.eth.net.getId();
      //   // setWalletInfo({ ...walletInfo, chainId, networkId });
      // });

      provider.on('networkChanged', async (networkId) => {
        window.location.reload();
        // const { web3 } = walletInfo;
        // const chainId = await web3.eth.chainId();
        // setWalletInfo({ ...walletInfo, chainId, networkId });
      });
      provider.on('disconnect', (error) => {
        console.log('DISCONNECT: ', error);
        setTimeout(() => history.push(HOME_ROUTE), 500);
      });
    },
    [resetWallet, history]
  );

  const onConnect = useCallback(async () => {
    try {
      const provider = await web3Modal.connect();
      await subscribeProvider(provider);

      const { web3, contracts } = initWeb3(provider);
      // if (web3Modal.cachedProvider !== WALLET_CONNECT) {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      const networkId = await web3.eth.net.getId();
      // chainId = await web3.eth.chainId();
      // } else {
      //   accounts = provider.accounts;
      //   address = accounts[0];
      //   networkId = provider.networkId;
      //   chainId = provider.chainId;
      // }
      const isWrongNetwork = networkId && networkId !== NETWORK_ID;
      setContractInfos(contracts);

      setWalletInfo({
        web3,
        provider,
        connected: true,
        address,
        // chainId,
        networkId,
        isWrongNetwork,
        cachedProvider: web3Modal.cachedProvider,
      });
    } catch (error) {
      console.log('Connect wallet fail: ', error);
      await resetWallet();
    }
  }, [resetWallet, subscribeProvider, web3Modal]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      onConnect();
    }
    customWalletModalUi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WalletContext.Provider
      value={{
        resetWallet,
        onConnect,
        web3Modal,
        walletInfo,
        contractInfos,
        setContractInfos,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletContext;
