import React from 'react';
import { Text } from '@blank/atoms';
import useAssetData from '@blank/hooks/useAssetData';
import { isNil } from 'ramda';

const AssetBreadcrumb = ({ collectionKey, id }) => {
  const { data } = useAssetData(collectionKey, id);
  const styleShort = {
    maxWidth: 300,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  };
  return (
    <Text {...styleShort}>
      {!isNil(data) ? data?.metadata?.name || id : ''}
    </Text>
  );
};

export default AssetBreadcrumb;
