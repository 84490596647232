import styled, { keyframes } from 'styled-components';
import { Icon } from '@blank/atoms';

const rotateKf = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const FlipPending = styled(Icon)`
  animation: ${rotateKf} 1.2s linear infinite;
`;

export default FlipPending;
