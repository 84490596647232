import ArtBounty from '@blank/contracts/ArtBounty.json';
import BlankCollectionFactory from '@blank/contracts/BlankCollectionFactory.json';
import ERC721Market from '@blank/contracts/ERC721Market.json';
// import Whitelist from '@blank/contracts/Whitelist.json';
import {
  BOUNTY_ADDRESS,
  FACTORY_ADDRESS,
  MARKET_ADDRESS,
  // WHITE_LIST_ADDRESS,
} from '@blank/constants/network';

export const FACTORY_NAME = 'BlankCollectionFactory';
export const CRYPTOPUNK_NAME = 'Cryptopunk';
export const MARKET_NAME = 'ERC721Market';
export const ARTBOUNTY_NAME = 'ArtBounty';
export const WHITE_LIST_NAME = 'Whitelist';
// export const BLANK_NFT_NAME = 'BlankNFT';

export const CONTRACT_INFOS = [
  {
    name: FACTORY_NAME,
    abi: BlankCollectionFactory,
    address: FACTORY_ADDRESS,
  },
  {
    name: MARKET_NAME,
    abi: ERC721Market,
    address: MARKET_ADDRESS,
  },
  {
    name: ARTBOUNTY_NAME,
    abi: ArtBounty.abi,
    address: BOUNTY_ADDRESS,
  },
  // {
  //   name: WHITE_LIST_NAME,
  //   abi: Whitelist,
  //   address: WHITE_LIST_ADDRESS,
  // },
  // {
  //   name: BLANK_NFT_NAME,
  //   abi: ArtBounty.abi,
  //   address: BOUNTY_ADDRESS,
  // },
];
