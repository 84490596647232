import { TRANSACTION_STORE_KEY } from './constants';
import { useAccount } from '../WalletContext/hooks';
import { filter } from 'ramda';
import { TRANSACTION_STATUS } from '../constants';

const useTransactionFromLocalstorage = () => {
  const account = useAccount();

  const oldStateData = localStorage.getItem(
    `${TRANSACTION_STORE_KEY}_${account}`
  );
  let oldTransactions = [];
  try {
    if (oldStateData) {
      const stateData = JSON.parse(oldStateData);
      oldTransactions = stateData?.transactions || [];
      oldTransactions = filter(
        (tx) => tx?.status === TRANSACTION_STATUS.pending
      )(oldTransactions);
    }
  } catch (error) {
    localStorage.removeItem(`${TRANSACTION_STORE_KEY}_${account}`);
  }

  localStorage.setItem(
    `${TRANSACTION_STORE_KEY}_${account}`,
    JSON.stringify({ transactions: oldTransactions || [] })
  );
  return oldTransactions || [];
};

export default useTransactionFromLocalstorage;
