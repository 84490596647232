import { toDataUrl } from 'blockies';
import css from '@styled-system/css';
import React, { memo } from 'react';

import { Image, Text, Box } from '@blank/atoms';
import { truncateAddress, checkExternalUrl } from '@blank/utils';

import EditCoverButton from './Layout/EditCoverButton';

const UserCover = memo(({ cover, hideEditBtn, src, ...props }) => {
  let urlPath;
  if (src) {
    urlPath = src;
  } else if (cover?.origin) {
    urlPath = `${process.env.REACT_APP_BACKEND_DOMAIN}/images/${
      cover.origin
    }?${Date.now()}`;
  } else {
    urlPath = '/assets/cover.svg';
  }

  return (
    <Box
      backgroundImage={`url('${urlPath}')`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      width="100%"
      height="100%"
      css={css({
        ':hover': {
          button: {
            opacity: '1',
            transition: 'opacity 0.5s',
          },
        },
        ':not(:hover)': {
          button: {
            opacity: '0.3',
            transition: 'opacity 0.5s',
          },
        },
      })}
      {...props}
    >
      {!hideEditBtn && (
        <EditCoverButton
          position="absolute"
          fontWeight="bold"
          fontSize={14}
          // padding="1rem 2rem"
          // width="17rem"
          // height="4rem"
          padding="5px 10px"
          width={135}
          height={32}
          left={35}
          top={132}
        >
          Edit cover photo
        </EditCoverButton>
      )}
    </Box>
  );
});

const UserAvatar = ({
  user = {},
  imageSize = 100,
  size = '2.4rem',
  ...props
}) => {
  const avatar = user.avatar || {};
  const fileName = avatar[`${imageSize}_${imageSize}`] || avatar.origin;
  const srcAvatar = checkExternalUrl(fileName)
    ? fileName
    : `${process.env.REACT_APP_BACKEND_DOMAIN}/images/${fileName}`;

  return (
    <Image
      src={fileName ? srcAvatar : toDataUrl(user.address || '0x0')}
      width={size}
      height={size}
      borderRadius="50%"
      {...props}
    />
  );
};

const UserName = ({ user = {}, ...props }) => (
  <Text {...props}>{user.username || truncateAddress(user.address)}</Text>
);

const UserDescription = ({ user = {}, ...props }) => (
  <Text {...props}>{user?.description || '...'}</Text>
);

export default {
  Avatar: UserAvatar,
  Name: UserName,
  Cover: UserCover,
  Description: UserDescription,
};
