import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { prop } from 'ramda';
import { Box, Flex, Link, Text } from '@blank//atoms';
import {
  // COLLECTIONS_ROUTE,
  HOME_ROUTE,
  // WHITE_WALL_ROUTE,
  // MINT_ROUTE,
  // SETTING_ROUTE,
} from '../../routeConstants';
// import { COLLECTION_TYPES, WHITE_WALL_TABS_NAME } from '@blank/constants';
import { truncateAddress } from '../../../utils';
import CurrentUser from '../CurrentUser';
import Dropdown from '@blank/components/Dropdown';
import { useResetWallet, useAccount } from '@blank/common/WalletContext/hooks';
import { useUserInfo } from '../ProfileContext';
import CopyButton from '@blank/components/CopyButton';
// import useWhiteListMember from '@blank/hooks/useWhiteListMember';

const itemHover = {
  background: 'rgba(0, 0, 0, 0.03)',
  color: '#000',
  span: {
    color: '#000',
  },
};

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  py: 15,
  px: 24,
  fontSize: 3,
  color: 'rgba(0, 0, 0, 0.3)',
  css: {
    ':hover': itemHover,
  },
};

// const subItemStyle = {
//   py: 15,
//   px: 25,
//   fontSize: 3,
//   fontWeight: 600,
//   cursor: 'pointer',
//   color: 'primaryNormalLight',
//   css: {
//     ':hover': itemHover,
//   },
// };

// const subItemHover = {
//   css: {
//     ':hover': {
//       color: '#000',
//     },
//   },
// };

// const UserNavItem = ({ item, onClick }) => {
//   const location = useLocation();
//   const matched = prop('isExact')(matchPath(location.pathname, item.path));
//   if (item.hasChild) return item.component;

//   return (
//     <Link
//       to={item.path}
//       {...itemStyle}
//       {...(matched && itemHover)}
//       onClick={onClick}
//     >
//       <Text fontWeight="600">{item.name}</Text>
//     </Link>
//   );
// };

// const LaunchSmartContract = ({ setLaunchSM }) => {
//   const whiteListMember = useWhiteListMember();
//   const [expand, setExpand] = useState(false);
//   const handleLaunchSM = (type) => {
//     setLaunchSM(type);
//   };

//   const onClickMenu = () => {
//     // setExpand(!expand);

//     if (whiteListMember) {
//       setExpand(!expand);
//       return;
//     }
//     handleLaunchSM(COLLECTION_TYPES.creations.type);
//   };

//   return (
//     <Box {...subItemStyle} onClick={onClickMenu}>
//       <Box>Launch Smart Contract</Box>
//       {expand && (
//         <Box fontSize="12px" mx="-25px" color="#B8B8B8">
//           <Box
//             py="12px"
//             pl="37px"
//             {...subItemHover}
//             onClick={() => handleLaunchSM(COLLECTION_TYPES.creations.type)}
//           >
//             Solo Smart Contract
//           </Box>
//           {whiteListMember && (
//             <>
//               <Box
//                 pb="12px"
//                 pl="37px"
//                 {...subItemHover}
//                 onClick={() => handleLaunchSM(COLLECTION_TYPES.groups.type)}
//               >
//                 Group Smart Contract
//               </Box>
//               <Box
//                 pl="37px"
//                 {...subItemHover}
//                 onClick={() => handleLaunchSM(COLLECTION_TYPES.collabs.type)}
//               >
//                 Collab Smart Contract
//               </Box>
//             </>
//           )}
//         </Box>
//       )}
//     </Box>
//   );
// };

// const USER_NAVS = (account, nftCollabs, whiteListMember, setLaunchSM) => [
//   {
//     path: MINT_ROUTE,
//     name: 'Mint New NFT',
//   },
//   {
//     hasChild: true,
//     component: <LaunchSmartContract setLaunchSM={setLaunchSM} />,
//   },
//   {
//     path: COLLECTIONS_ROUTE,
//     name: !whiteListMember ? 'My NFTs' : 'My Studio',
//   },
//   {
//     path: WHITE_WALL_ROUTE.replace(':account', account).replace(
//       ':tab',
//       WHITE_WALL_TABS_NAME.profile
//     ),
//     name: 'Profile',
//   },
//   {
//     path: SETTING_ROUTE,
//     name: 'Edit Profile',
//   },
// ];

// const title =
//     !whiteListMember && isEmpty(nftCollabs) ? 'My Collections' : 'My Studio';

const UserNav = ({ nftCollabs, whiteListMember, setLaunchSM }) => {
  const history = useHistory();
  const account = useAccount();
  const resetWallet = useResetWallet();
  const { username } = useUserInfo();
  const [isCloseDropDown, setCloseDropDown] = useState(false);

  // const closeDropDown = () => {
  //   setCloseDropDown(true);
  // };

  return (
    <Dropdown
      isCloseDropDown={isCloseDropDown}
      height={45}
      wrapperProps={{
        display: 'flex',
      }}
      openContentProps={{
        borderRadius: 10,
      }}
      content={
        <Flex
          flexDirection="column"
          width={235}
          fontWeight="normal"
          boxShadow="0px 2px 12px rgba(0, 0, 0, 0.12)"
          color="primary"
          borderRadius={10}
        >
          <Box
            p="27px 0 22px"
            mx="25px"
            // borderBottom="0.3px solid rgba(0, 0, 0, 0.2)"
          >
            <Text display="block" fontSize={4} fontWeight="bold" mb="3px">
              {username ? `@${username}` : truncateAddress(account)}
            </Text>
            {username && (
              <CopyButton
                text={account}
                textDisPlay={truncateAddress(account)}
                mr={3}
                fontSize="10px"
              />
            )}
          </Box>
          {/* {USER_NAVS(account, nftCollabs, whiteListMember, setLaunchSM).map(
            (item, index) => (
              <UserNavItem
                key={`item${index}`}
                item={item}
                onClick={closeDropDown}
              />
            )
          )} */}
          <Link
            onClick={async () => {
              await resetWallet();
              history.push(HOME_ROUTE);
            }}
            {...itemStyle}
            borderRadius="0px 0px 8px 8px"
          >
            <Text fontWeight="600">Log Out</Text>
          </Link>
        </Flex>
      }
    >
      <CurrentUser.Avatar
        onClick={() => setCloseDropDown(false)}
        size="45px"
        // boxShadow="0px 3px 13px rgba(0, 0, 0, 0.15)"
        filter="drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))"
      />
    </Dropdown>
  );
};

export default UserNav;
