import { useCallback } from 'react';

import { useFetcher } from './useFetch';

export const usePost = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(
    (dataObj) => {
      return fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};

export const usePut = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(
    (dataObj) => {
      return fetcher(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};

export const usePatch = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(
    (dataObj) => {
      return fetcher(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};

export const useDelete = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(
    (dataObj) => {
      return fetcher(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};

const appendObject = (formData, obj, namespace) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyName = [namespace, '[', key, ']'].join('');
      formData.append(keyName, obj[key]);
    }
  }
};

const appendArray = (formData, array, namespace) => {
  for (const index in array) {
    const item = array[index];
    if (item && typeof item == 'object' && item.constructor === Object) {
      appendObject(formData, item, `${namespace}[${index}]`);
    } else {
      const keyName = `${namespace}[${index}]`;
      formData.append(keyName, item);
    }
  }
};

export const usePostFormData = (url, opts) => {
  const [fetcher, result] = useFetcher();

  const triggerFetch = useCallback(
    (dataObj) => {
      const formData = new FormData();
      for (const key in dataObj) {
        const value = dataObj[key];
        if (value && typeof value == 'object' && value.constructor === Object) {
          appendObject(formData, value, key);
        } else if (
          value &&
          typeof value == 'object' &&
          value.constructor === Array
        ) {
          appendArray(formData, value, key);
        } else {
          formData.append(key, value);
        }
      }

      return fetcher(url, {
        method: 'POST',
        body: formData,
        ...opts,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, url, JSON.stringify(opts)]
  );

  return [triggerFetch, result];
};
