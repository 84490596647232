import { useCallback } from 'react';
import { useWeb3 } from '@blank/common/WalletContext/hooks/useWeb3';
import { COMPLETE_TRANSACTION, REMOVE_TRANSACTION } from './constants';
import { TRANSACTION_STATUS } from '../constants';
import { isNil } from 'ramda';

const useSubcribeTransactionStatus = (dispatch) => {
  const web3 = useWeb3();

  const subcribeTransactionStatus = useCallback(
    (transaction) => {
      if (!transaction?.hash) {
        return;
      }
      const intervalInstance = setInterval(() => {
        web3.eth.getTransaction(transaction.hash, (error, result) => {
          if (error) {
            clearInterval(intervalInstance);
            console.error(
              '[error] get Transaction infomation ',
              transaction.hash,
              ': ',
              error
            );
            return;
          }
          if (!result) {
            console.log(
              '[Check in localstorage] Transaction is invalid: ',
              transaction.hash
            );
            clearInterval(intervalInstance);
            dispatch({
              type: REMOVE_TRANSACTION,
              payload: transaction,
            });
            return;
          }
          const transactionIndex = result.transactionIndex;
          if (!isNil(transactionIndex)) {
            clearInterval(intervalInstance);
            web3.eth
              .getTransactionReceipt(transaction.hash)
              .then((resultTx) => {
                const status = resultTx?.status;

                dispatch({
                  type: COMPLETE_TRANSACTION,
                  payload: {
                    transaction,
                    status: status
                      ? TRANSACTION_STATUS.success
                      : TRANSACTION_STATUS.error,
                  },
                });
                setTimeout(() => {
                  dispatch({
                    type: REMOVE_TRANSACTION,
                    payload: transaction,
                  });
                }, 5000);
              })
              .catch(() => {
                dispatch({
                  type: REMOVE_TRANSACTION,
                  payload: transaction,
                });
              });
          }
        });
      }, 1000);
    },
    [dispatch, web3]
  );

  return subcribeTransactionStatus;
};

export default useSubcribeTransactionStatus;
