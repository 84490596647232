import { lazy } from 'react';
import {
  // EXPLORE_ROUTE,
  // SETTING_ROUTE,
  // MINT_ROUTE,
  // WHITE_WALL_ROUTE,
  // WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  // // WALL_ASSET_ROUTE,
  // // MARKET_ASSET_ROUTE,
  // PRIVACY_ROUTE,
  // FAQ_ROUTE,
  // HACKATAO_ROUTE,
  // ABOUT_ROUTE,
  // VIEWER_ROUTE,
  // COLLECTION_PUBLIC_ROUTE,
  // COLLECTION_ROUTE,
  // COLLECTIONS_ROUTE,
  // ART_WORK_ROUTE,
  // ART_WORK_PUBLIC_ROUTE,
  // WHITE_WALL_ART_WORK_ROUTE,
  ADMIN_ROUTE,
} from './routeConstants';

const routeConfigs = [
  // {
  //   exact: true,
  //   path: COLLECTIONS_ROUTE,
  //   component: lazy(() => import('./Collections')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTIONS_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: COLLECTION_ROUTE,
  //   component: lazy(() => import('./Collection')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTIONS_ROUTE, COLLECTION_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: VIEWER_ROUTE,
  //   component: lazy(() => import('./Viewer')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTIONS_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: COLLECTION_PUBLIC_ROUTE,
  //   component: lazy(() => import('./Collection')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_PUBLIC_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: ABOUT_ROUTE,
  //   component: lazy(() => import('./About')),
  //   breadcrumbsConfig: [ABOUT_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: HACKATAO_ROUTE,
  //   component: lazy(() => import('./AssetPage/HackataoAssetPage')),
  // },
  // {
  //   exact: true,
  //   path: FAQ_ROUTE,
  //   component: lazy(() => import('./FAQ')),
  //   breadcrumbsConfig: [FAQ_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: PRIVACY_ROUTE,
  //   component: lazy(() => import('./Privacy')),
  //   breadcrumbsConfig: [PRIVACY_ROUTE],
  // },
  // // {
  // //   exact: true,
  // //   path: MARKET_ASSET_ROUTE,
  // //   component: lazy(() => import('./AssetPage')),
  // // },
  // {
  //   exact: true,
  //   path: ART_WORK_ROUTE,
  //   component: lazy(() => import('./AssetPage')),
  //   breadcrumbsConfig: [
  //     EXPLORE_ROUTE,
  //     COLLECTIONS_ROUTE,
  //     COLLECTION_ROUTE,
  //     ART_WORK_ROUTE,
  //   ],
  // },
  // {
  //   exact: true,
  //   path: ART_WORK_PUBLIC_ROUTE,
  //   component: lazy(() => import('./AssetPage')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_PUBLIC_ROUTE, ART_WORK_ROUTE],
  // },
  // // {
  // //   exact: true,
  // //   path: WALL_ASSET_ROUTE,
  // //   component: lazy(() => import('./AssetPage')),
  // // },
  // {
  //   exact: true,
  //   path: WHITE_WALL_ART_WORK_ROUTE,
  //   component: lazy(() => import('./AssetPage')),
  //   breadcrumbsConfig: [
  //     EXPLORE_ROUTE,
  //     WHITE_WALL_ROUTE,
  //     WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  //     WHITE_WALL_ART_WORK_ROUTE,
  //   ],
  // },
  // {
  //   exact: true,
  //   path: WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  //   component: lazy(() => import('./Collection')),
  //   breadcrumbsConfig: [
  //     EXPLORE_ROUTE,
  //     WHITE_WALL_ROUTE,
  //     WHITE_WALL_EXPLORE_COLLECTION_ROUTE,
  //   ],
  // },
  // {
  //   exact: true,
  //   path: WHITE_WALL_ROUTE,
  //   component: lazy(() => import('./WhiteWall')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, WHITE_WALL_ROUTE],
  // },
  // // {
  // //   exact: true,
  // //   path: ART_WORK,
  // //   component: lazy(() => import('./AssetPage')),
  // //   breadcrumbsConfig: [EXPLORE_ROUTE, COLLECTION_PUBLIC_ROUTE, ART_WORK],
  // // },
  // {
  //   exact: true,
  //   path: MINT_ROUTE,
  //   component: lazy(() => import('./Mint')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, MINT_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: SETTING_ROUTE,
  //   component: lazy(() => import('./Setting')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE, SETTING_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: EXPLORE_ROUTE,
  //   component: lazy(() => import('./Explore')),
  //   breadcrumbsConfig: [EXPLORE_ROUTE],
  // },
  // {
  //   exact: true,
  //   path: DEFAULT_ROUTE,
  //   component: lazy(() => import('./Explore/Default')),
  //   breadcrumbsConfig: [DEFAULT_ROUTE],
  // },
  {
    exact: true,
    path: ADMIN_ROUTE,
    component: lazy(() => import('./Admin')),
    breadcrumbsConfig: [ADMIN_ROUTE],
  },
];

export default routeConfigs;
