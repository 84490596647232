import { useHistory } from 'react-router-dom';
import React from 'react';

import { Box, Button, Flex, Text } from '../../atoms';
import { DOMAIN, NETWORK_NAME } from '../../constants/network';
import { HOME_ROUTE } from '../routeConstants';
// import { useRemoveWallet } from '@blank/common/WalletContext';
import Modal from '../../components/Modal';
import { useResetWallet } from '@blank/common/WalletContext/hooks';

const WrongNetworkModal = () => {
  const history = useHistory();
  const resetWallet = useResetWallet();

  return (
    <Modal open width={700} p={40} textAlign="center">
      <Flex flexDirection="column" alignItems="center" fontWeight="bold">
        <Box mt={10} mb={28}>
          To access {DOMAIN}, please switch to{' '}
          <Text color="text">{NETWORK_NAME}</Text>
        </Box>
        <Button
          onClick={() => {
            resetWallet();
            history.push(HOME_ROUTE);
          }}
        >
          Okay
        </Button>
      </Flex>
    </Modal>
  );
};

export default WrongNetworkModal;
