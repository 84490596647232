import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Icon } from '../atoms';

const modalRoot = document.getElementById('modal-root');

export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';

const Dropdown = ({
  content,
  caret,
  position = RIGHT,
  children,
  alignItems = CENTER,
  boxHeight = 'auto',
  openProps,
  openContentProps,
  alwaysOpen = false,
  wrapperProps,
  wrapperOpenProps,
  autoClose,
  isCloseDropDown,
  ...props
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const closeDropdown = useCallback(() => setOpen(false), []);
  const toggleDropdown = useCallback(() => setOpen((open) => !open), []);

  useEffect(() => {
    if (!ref.current) return;
    const checkToClose = (e) => {
      if (!ref.current.contains(e.target) && !modalRoot.contains(e.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('click', checkToClose);

    return () => document.removeEventListener('click', checkToClose);
  }, [closeDropdown]);

  useEffect(() => {
    if (isCloseDropDown) setOpen(false);
  }, [isCloseDropDown]);

  const positionProps = {
    [LEFT]: {
      left: 0,
    },
    [RIGHT]: {
      right: 0,
    },
    [CENTER]: {
      left: '50%',
      css: `
        >div {
          position: relative;
          left: -50%;
        }
      `,
    },
  }[position];

  return (
    <Box
      display="inline-flex"
      alignItems={alignItems}
      height={boxHeight}
      position="relative"
      ref={ref}
      {...wrapperProps}
      {...(open && wrapperOpenProps)}
    >
      <Button type="button" variant="link" onClick={toggleDropdown} {...props}>
        {children}
        {caret && (
          <Icon name="caret" color="primaryLight" size="0.75em" ml={1} />
        )}
      </Button>
      {(open || alwaysOpen) && (
        <Box
          position="absolute"
          top="calc(100% + 5px)"
          zIndex={5}
          {...(autoClose && { onClick: toggleDropdown })}
          {...positionProps}
          {...openProps}
        >
          <Box
            bg="background"
            fontFamily="normal"
            minWidth={160}
            {...openContentProps}
          >
            {content}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dropdown;
