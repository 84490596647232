export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const COLLECTION_COLORS = [
  '#FF4499',
  '#FF4F5A',
  '#B55FFF',
  '#B55FFF',
  '#44F0E5',
  '#C2FE3A',
  '#FFB939',
];

export const COLLECTION_TYPES = {
  creations: {
    type: 'creations',
    title: 'Solo',
    typeKey: 'creation',
    key: 1,
  },
  collabs: {
    type: 'collabs',
    title: 'Collab',
    typeKey: 'collab',
    key: 2,
  },
  groups: {
    type: 'groups',
    title: 'Group',
    typeKey: 'group',
    key: 3,
  },
  volatilityArt: {
    type: 'volatilityArt',
    title: 'Volatility Art',
    // typeKey: 'group',
    // key: 3,
  },
  collections: {
    type: 'collections',
    title: 'Collection',
    typeKey: 'collection',
  },
};

export const MEMBER_TYPE = {
  founder: 1,
  member: 2,
};

export const NOTIFICATION_TYPES = {
  RECEIVED_INVITATION: 1, // there is new invitation to join collab from other user
  ANOTHER_REJECT_INVITATION: 2, // another user rejected your invatation
  RECEIVED_ASSET: 3, // another user transfer asset
};

export const IPFS_TYPES = {
  asset: 'asset',
};

export const WHITE_WALL_TABS_NAME = {
  profile: 'profile',
  activity: 'activity',
  explore: 'explore',
};

export const BYTE_PER_MB = 1000000;
export const MINT_MAX_FILE_SIZE = 100;
export const MINT_MAX_THUMBNAIL_SIZE = 30;
export const MINT_FILE_TYPES = [
  'video/mp4',
  'audio/mpeg',
  'image/png',
  'image/jpeg',
  'image/gif',
];
export const MINT_THUMBNAIL_TYPES = ['image/png', 'image/jpeg', 'image/gif'];
export const VIDEO_TYPES = ['video/mp4'];
export const AUDIO_TYPES = ['audio/mpeg'];
export const MINT_FILE_HAVE_THUMBNAL = [...VIDEO_TYPES, ...AUDIO_TYPES];
