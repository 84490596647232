import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

// Copy from: https://github.com/jaredpalmer/formik/issues/146#issuecomment-474775723
const useFormikFocusFirstError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
      prevSubmitCountRef.current = formik.submitCount;
    }
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

export default useFormikFocusFirstError;
